import React, { useEffect, useState } from "react";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import Select from "react-select";
import TopShowMessage from "../component/TopShowMessage";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDebounce } from "use-debounce";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import InventoryModalForm from "./InventoryModalForm";
import { maxWidth } from "@mui/system";
import InventoryTable from "./InventoryTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "50%",
    maxWidth:"90%",
  },
};

const AddInventory = () => {
  const history = useHistory();
  const userRole = localStorage.getItem("USER_ROLE");
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchValue] = useDebounce(searchText, 1000);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [formShowData, setFormShowData] = useState({
  //   warehouseId: "",
  //   warehouseName: "",
  //   pincode: "",
  //   city: "",
  //   state: "",
  //   address: "",
  // });

  const [formShowData, setFormShowData] = useState({
    warehouseId: "",
    warehouseName: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
    // isDelete: false,
  });


  // const updateOrderStatus = async (id) => {
  //   setIsLoadingOn(true);
  //   const tempData = await getData(`warehouse/getInventoryDetails?warehouseId=${id}`);
    
  //   setIsLoadingOn(false);
  //   if (tempData.statusCode == 200) {
  //     const settingData = tempData?.data?.data || [] 
  //     setInventoryData(settingData);
  //     const item = settingData[0] || [];
      
  //     setFormShowData({
  //       warehouseName: item?.warehouseName,
  //       pincode: item?.pincode,
  //       city: item?.city,
  //       state: item?.state,
  //       address: item?.address,
  //       warehouseId: item?.warehouseId,
  //       isDelete: item?.isDeleted,
  //     });
  //   }
  // };

  const updateOrderStatus = async (id) => {
    try {
      setIsLoadingOn(true);

      // Make API calls
      const [orderStatusResponse, inventoryResponse] = await Promise.all([
        getData(`warehouse/getInventoryDetails?warehouseId=${id}`),
        getData(`warehouse/getInventory?warehouseId=${id}`),
      ]);

      // Handle `updateOrderStatus` response
      if (orderStatusResponse?.statusCode === 200) {
        const orderData = Array.isArray(orderStatusResponse?.data?.data)
          ? orderStatusResponse?.data?.data
          : [];
        setInventoryData(orderData);

        if (orderData.length > 0) {
          const item = orderData[0];
          setFormShowData((prev) => ({
            ...prev,
            warehouseName: item?.warehouseName || "",
            pincode: item?.pincode || "",
            city: item?.city || "",
            state: item?.state || "",
            address: item?.address || "",
            warehouseId: item?.warehouseId || "",
            isDelete: item?.isDeleted || false,
          }));
        }
      }

      // Handle `getInventory` response
      if (inventoryResponse?.statusCode === 200) {
        const inventoryData = Array.isArray(inventoryResponse?.data)
          ? inventoryResponse?.data
          : [];

        if (inventoryData.length > 0) {
          const item = inventoryData[0];
          setFormShowData((prev) => ({
            ...prev,
            warehouseName: item?.warehouseName || "",
            pincode: item?.pincode || "",
            city: item?.city || "",
            state: item?.state || "",
            address: item?.address || "",
            warehouseId: item?.warehouseId || "",
            isDelete: item?.isDeleted || false,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingOn(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");

    if (id) {
      updateOrderStatus(id);
    }
  }, []);


  const statusModalOpen = (rowData) => {
    setSelectedRow(rowData); // Save the row data to state
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  return (
    <>
      {isLoadingOn && <Loader />}
      <div className="productData">
          <h4>
            <b>Warehouse Details</b>
          </h4>
        <div className="formsec">
          <div className="row">
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="warehouseId" className="form-label">
                  Warehouse Id<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  id="warehouseId"
                  name="warehouseId"
                  value={formShowData?.warehouseId}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="warehouseName" className="form-label">
                  Warehouse Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  id="warehouseName"
                  name="warehouseName"
                  value={formShowData?.warehouseName}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="pincode" className="form-label">
                  Pincode<span>*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Pincode"
                  className="form-control"
                  id="pincode"
                  name="pincode"
                  value={formShowData?.pincode}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter City"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formShowData?.city}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="state" className="form-label">
                  State<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter State"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formShowData?.state}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formShowData?.address}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inventoryDataSection">
        <div className="updateInventory">
          <button onClick={statusModalOpen} className="btn btn-success">
            Add Inventory
          </button>
        </div>
          <InventoryTable
            data={inventoryData}
            userRole={userRole}
            statusModalOpen={statusModalOpen}
          />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button className="modalCloseBtn" onClick={closeModal}>
            <FaRegTimesCircle />
          </button>
          {isLoadingOn ? <Loader /> : ""}
          {/* <h4 style={{marginTop:15, marginLeft:20,}}>Inventory Details</h4> */}
          <h4  style={{marginTop:15, marginLeft:20,}}>{selectedRow?.productId ? "Update Inventory" : "Add Inventory"}</h4>
          <div className="modalUserPaymentStatus">
            <InventoryModalForm
              closeModal={closeModal}
              updateOrderStatus={() => updateOrderStatus(id)}
              selectedRow={selectedRow}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddInventory;
