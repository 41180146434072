import React, { useMemo, useState, useEffect } from 'react';
import '../css/makeTable.css'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import Moment from 'react-moment';
import Modal from 'react-modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import { formatDateTimeSecond } from '../component/common';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: "none"
  },
};

function LeadMerchantDetails(props) {


  const urlSearchParams = new URLSearchParams(window.location.search);
  const leadId = urlSearchParams.get("leadId");

  const [merchantPersonalData, setMerchantPersonalData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  async function merchantGetData() {
    if (leadId) {
      setIsLoadingOn(true);
      const filterData = {
        leadId : leadId
      }
      const tempData = await postData(`merchantLead/leadMerchant`, filterData)
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setMerchantPersonalData(tempData?.data?.data[0]);
      }
    }
  }

  useEffect(() => {
    merchantGetData();
  }, [leadId])

  const data = useMemo(
    () => [
      { id: 1, name: 'John Doe', age: 25, city: 'New York', mobileNo: '9988776786' },
      { id: 2, name: 'Jane Smith', age: 30, city: 'London', mobileNo: '9988776786' },
      { id: 3, name: 'Bob Johnson', age: 35, city: 'Paris', mobileNo: '9988776786' },
      // Add more data as needed
    ],
    []
  );
  const [activeTab, setActiveTab] = useState("home");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: "leadId",
        Cell: ({ cell }) => {
          return (
            <>
              <span style={{ fontWeight: "600" }}>{cell.row.index + 1}</span>
            </>
          );
        },
      },
      {
        Header: 'Interested',
        accessor: 'isInterested'
      },
      {
        Header: 'Reason',
        accessor: 'reason'
      },
      {
        Header: 'Uploaded Date',
        accessor: 'updatedAt',
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original.createdAt}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const handleOpenViewImage = (filePath)=>{
    setModalImageURl(filePath);
    setIsOpen(true);
    }

  return (
    <div>
      <div className="container-fluid">
        <div className="row alignmentTable topOrderHeaderBorder mb-3">
          <div className="col-md-10">
            <h5 style={{ marginBottom: 0 }}>Lead Merchant Details</h5>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs creditReportTab" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected={activeTab === "home"}
            onClick={() => handleTabClick("home")}
          >
            <strong>Personal Info</strong>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected={activeTab === "profile"}
            onClick={() => handleTabClick("profile")}
          >
           <strong>Documents</strong> 
          </button>
        </li>

      </ul>
      {isLoadingOn ? <Loader /> : ""}
      <div className="tab-content" id="myTabContent">
        {activeTab === "home" && (
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <section className="orderDetailsSection">
              <div className="container-fluid">
                <div className="row" style={{ marginLeft: "-25px", marginTop: "25px" }}>
                  <div className="col-md-6">
                    <div className="basicDetailsUser">
                      <table className="userDetailsTableInfo">
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan={2}>User Info </th>

                        </tr>
                        <tr>
                          <th>Merchant Name :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.merchantName : ""}</td>
                        </tr>
                        <tr>
                          <th>Merchant Mobile :</th>
                          <td>{merchantPersonalData && merchantPersonalData?.merchantMobile ? "XXXXXX" + merchantPersonalData.merchantMobile?.slice(-4) : "-"}</td>
                        </tr>
                        <tr>
                          <th>Employee Name:</th>
                          <td>
                            {merchantPersonalData?.employeeName ? merchantPersonalData?.employeeName :"N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Employee Mobile:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.employeeMobile ? "XXXXXX" + merchantPersonalData.employeeMobile?.slice(-4) : "-"}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>Email:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData.email : ""}
                          </td>
                        </tr> */}
                        <tr>
                          <th>Pan Number:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.panNumber == null ? "N/A" : merchantPersonalData?.panNumber}
                          </td>
                        </tr>
                        <tr>
                          <th>GST Number:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.gstNumber == null ? "N/A" : merchantPersonalData?.gstNumber}
                          </td>
                        </tr>
                        <tr>
                          <th>Udhyam Number:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.udhyamNumber == null ? "N/A" : merchantPersonalData?.udhyamNumber}
                          </td>
                        </tr>
                        <tr>
                          <th>Address :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.address : ""}</td>
                        </tr>
                        <tr>
                          <th>Pin Code :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.pincode : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>City :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.city : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>State :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.state : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Created At:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.createdAt : ''}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="basicDetailsUser">
                      <table className="userDetailsTableInfo">
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan={2}>Shop Info </th>

                        </tr>
                        <tr>
                          <th>Shop Name :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.shopName : ""}</td>
                        </tr>
                        <tr>
                          <th>Lead Type	 :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.leadType : ""}</td>
                        </tr>
                        {/* <tr>
                          <th>Shop Property:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData.shopProperty : ""}
                          </td>
                        </tr> */}
                        <tr>
                          <th>Shop Size:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.shopSize == null ? "N/A" : merchantPersonalData?.shopSize}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>Category Name:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.categoryName == null ? "N/A" : merchantPersonalData?.categoryName}
                          </td>
                        </tr>
                        <tr>
                          <th>Sub-Category Name	 :</th>
                          <td>
                          {merchantPersonalData && merchantPersonalData?.subCategoryName == null ? "N/A" : merchantPersonalData?.subCategoryName}
                          </td>
                        </tr> */}
                        <tr>
                          <th>Visit Date :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.createdAt : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Next Visit Date :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.nextVisitDate : ''}
                          </td>
                        </tr>

                        <tr>
                          <th>Remarks:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.remark : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Comment:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.comment : ''}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                </div>
                {/* -------------------- form row ---------------------- */}
              </div>
            </section>
          </div>
        )}
         {activeTab === "profile" && (
          <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <section className="orderDetailsSection">
              <div className="container-fluid">
                <div className="row" style={{ marginLeft: "-25px", marginTop: "25px" }}>
                  <div className="col-md-12">
                    <div className="basicDetailsUser">
                      <table className="userDetailsTableInfo">
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan={2}>Interest/Documents Info </th>

                        </tr>
                        <tr>
                          <th>{merchantPersonalData?.disposition ? "Disposition :" : "Feedback Status :"}</th>
                          {/* <td>{merchantPersonalData ? merchantPersonalData.isInterested : ""}</td> */}
                          <td>{merchantPersonalData?.disposition ? merchantPersonalData?.disposition : merchantPersonalData?.feedbackStatus}</td>
                        </tr>
                        <tr>
                          <th>Reason:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.reason : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Comment:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.comment : ""}
                          </td>
                        </tr>

                        <tr>
                          <th>Selfie Photo:</th>
                          {/* <td>
                            {merchantPersonalData && merchantPersonalData.documentTypeId == 11
                            ?
                            <img onClick={()=>handleOpenViewImage(merchantPersonalData.selfie)} src={merchantPersonalData.selfie} alt="Shop Photo" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                            : "N/A"}
                          </td> */}
                          <td>
                            <img onClick={()=>handleOpenViewImage(merchantPersonalData?.selfie)} src={merchantPersonalData?.selfie} alt="Shop Photo" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                          </td>
                        </tr>

                        {/* <tr>
                          <th>Merchnat FOS Photo:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData.documentTypeId == 9
                            ?<img onClick={()=>handleOpenViewImage(merchantPersonalData.filePath)} src={merchantPersonalData.filePath} alt="Shop Photo" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                            : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <th>Inside Shop Photo:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData.documentTypeId == 19
                            ?<img onClick={()=>handleOpenViewImage(merchantPersonalData.filePath)} src={merchantPersonalData.filePath} alt="Shop Photo" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                            : "N/A"}
                          </td>
                        </tr> */}
                    
                      </table>
                    </div>
                  </div>

                </div>
                {/* -------------------- form row ---------------------- */}
              </div>
            </section>
          </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={()=>setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={()=>setIsOpen(false)}>
          <FaRegTimesCircle />
        </button>
        {modalImageURl && (
          <div className="modalUserImage">
            <img
              src={modalImageURl}
              alt="modalImage"
              style={{maxWidth:"100%", height:"100vh",}}
            />
          </div>
        )}
      </Modal>
    </div>
  )
}




export default LeadMerchantDetails;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>

        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "↓"
                          : "↑"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                      <th
                        colSpan={visibleColumns.length}
                        style={{
                          textAlign: 'left',
                        }}
                      >
                      </th>
                    </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                    {footerGroups.map(group => (
                      <tr {...group.getFooterGroupProps()}>
                        {group.headers.map(column => (
                          <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                      </tr>
                    ))}
                  </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
