import { useHistory } from "react-router-dom";

const UnauthorizedMessage = () => {
  const history = useHistory();

  const handleGoHome = () => {
    history.push("/"); // Redirect to the home page
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-xl rounded-2xl p-8 max-w-md text-center border border-red-200">
        <h1 className="text-2xl font-bold text-red-600 mb-2">Unauthorized Access</h1>
        <p className="text-gray-600 mb-6">
          <b> You do not have permission to view this page </b>
        </p>
        <button
          onClick={handleGoHome}
          className="bg-red-500 text-black px-6 py-3 rounded-full shadow-md hover:bg-red-600 hover:text-white transition duration-300 ease-in-out"
          style={{ marginBottom: "10px", borderRadius: "9999px" }}
        >
          <b> Go to Home Page </b>
        </button>
      </div>
    </div>
  );
};

export default UnauthorizedMessage;