import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import InventoryModalForm from "./InventoryModalForm";
import InventoryTable from "./InventoryTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "90%",
    maxWidth: "90%",
  },
};

function InventoryList() {
  const userRole = localStorage.getItem("USER_ROLE");
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleInputChange = (e) => {
    setSearchBoxValue(e.target.value);
  };
  const inputRef = useRef();

  const statusModalOpen = (rowData) => {
    setSelectedRow(rowData); // Save the row data to state
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    updateOrderStatus();
    setSelectedOptionStatus(null);
  };

  const searchManualData = async () => {
    let filterData = {};
    if (searchBoxValue) {
      filterData = {
        warehouseName: searchBoxValue,
        pageSize: 10,
        page: 1,
      };
    } 
    else {
      alert("Please enter the Warehose Name");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`warehouse/getInventoryDetails?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData?.data?.data || []);
    }
  };
  const updateOrderStatus = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("warehouse/getInventoryDetails");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData?.data?.data  || []);
    }
  };

  useEffect(() => {
    updateOrderStatus();
  }, []);

  const merchantOption = [
    { value: "", label: "Warehouse Name" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <h4 style={{ marginBottom: 10 }}>Inventory List</h4>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={
                  merchantRefrenceType === "userId" || "mobileNumber"
                    ? "tel"
                    : "text"
                }
                className="searchInputField"
                ref={inputRef}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            <div className="col-md-4 d-flex justify-content-end" onClick={resetFilters}>
              <button onClick={statusModalOpen} className="btn btn-success">
                Add Inventory
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="inventoryDataSection">
        {merchantUserData && (
          <InventoryTable
            data={merchantUserData}
            userRole={userRole}
            statusModalOpen={statusModalOpen}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <h4 className="pleaseSelectStatus">{selectedRow?.productId ? "Update Inventory" : "Add Inventory"}</h4>
        <div className="modalUserPaymentStatus">
          <InventoryModalForm
            closeModal={closeModal}
            updateOrderStatus={() => updateOrderStatus()}
            selectedRow={selectedRow}
          />
        </div>
      </Modal>
    </div>
  );
}

export default InventoryList;
