import React, { useEffect, useState, useMemo } from "react";
import { getData, getCmsData } from "../../store/utils";
import Swal from "sweetalert2";
import Loader from "../../component/Loader";
import Table from "../../pages/ReactTable";  
import { formatDateTimeSeconds } from "../../component/common";
import { postData } from "../../store/utils";

const CollectionDetails = () => {
  const [merchantUserData, setMerchantUserData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loanIdArray, setLoanIdArray] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");
  const [fmiData, setFmiData] = useState();
  const [orderData, setOrderData] = useState();

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getCollectionHistory(pageIndex, pageSize, column.id, newOrderBy);
  };

  const extractLoanIds = (data) => {return data.filter(item => item.loanId).map(item => item.loanId)};

  const getFmiOrderDetails = async (userId) => {
    setIsLoadingOn(true);
    const tempData = await getData(`fundMyInvoice/getFMIData/${userId}`);
    setIsLoadingOn(false);
  
    if (tempData.statusCode === 200) {
      setFmiData(tempData?.data);
      const fmiLoanIds = extractLoanIds(tempData.data);
      sendLoanIdsToCollection(fmiLoanIds);
    }
  };

  const getMerchantOrderDetails = async (userId) => {
    let filterData = {
      userId: userId,
      orderBy: 'DESC',
    };
    setIsLoadingOn(true);
    const tempData = await postData("order/getOrders", filterData);
    setIsLoadingOn(false);
  
    if (tempData.statusCode === 200) {
      setOrderData(tempData?.data?.records)
      const orderLoanIds = extractLoanIds(tempData.data.records);
      sendLoanIdsToCollection(orderLoanIds);
    }
  };

  let combinedLoanIds = [];
  const sendLoanIdsToCollection = (loanIds) => {
    combinedLoanIds = [...new Set([...combinedLoanIds, ...loanIds])];
    console.log("combinedLoanIds ==", combinedLoanIds);
    setLoanIdArray(combinedLoanIds)
  };  

  const getCollectionHistory = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    try {
      setIsLoadingOn(true);
  
      const formattedLoanIds = loanIdArray.map(id => `loanId${id}`).join(',');
      let apiUrl = `disposition/collectionHistory?page=${page + 1}&pageSize=${pageSize}&loanIdArray=[${formattedLoanIds}]`;
  
      if (sortBy && orderBy) {
        apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
      }
  
      console.log("API Request URL:", apiUrl);
  
      const tempData = await getCmsData(apiUrl);
      console.log("tempData:", tempData);
  
      setIsLoadingOn(false);
  
      if (tempData.statusCode === 200 && Array.isArray(tempData.data) && tempData.data.length > 0) {
        setMerchantUserData(tempData.data);
        setPageCount(tempData.pagination?.totalPages || 0);
        setTotalRecords(tempData.pagination?.totalRecords || 0);
        setErrorMessage("");
      } else {
        setMerchantUserData([]);
        setPageCount(0);
        setTotalRecords(0);
        setErrorMessage("No collection history found.");
      }
    } catch (error) {
      setIsLoadingOn(false);
      console.error("Error fetching collection history:", error);
  
      Swal.fire({
        title: "Error!",
        text: error?.message || "Something went wrong!!!",
        icon: "error",
      });
    }
  };
  
  useEffect(() => {
    if(userId) {
      getFmiOrderDetails(userId);
      getMerchantOrderDetails(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (loanIdArray.length > 0) {
      getCollectionHistory(pageIndex, pageSize, sortBy, orderBy,);
    }
  }, [pageIndex, pageSize, sortBy, orderBy, loanIdArray]);

  const imageViewModal = (filePath) => {
    setModalImageURl(filePath);
    setIsOpen(true);
  };
  
  const closeModal = () => {
    setIsOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => <span style={{ fontWeight: "600", textAlign: "center"}}>{cell.row.index + 1}{"."}</span>,
      },
      {
        Header: "Loan Id",
        accessor: "loanId",
        Cell: ({ cell }) => <span style={{ fontWeight: "600" }}>{cell.value || "-"}</span>,
      },
      {
        Header: "Disposition Code",
        accessor: "dispositionCode",
        Cell: ({ cell }) => <span style={{ fontWeight: "600" }}>{cell.value || "-"}</span>,
      },
      {
        Header: "Disposition Date",
        accessor: "dispositionDate",
        Cell: ({ cell }) => {
            return (
              <>
                <h7>{cell.row.original.dispositionDate && cell.row.original.dispositionDate !== null ? formatDateTimeSeconds(cell.row.original.dispositionDate) : "-"}</h7>
              </>
            );
        },
      },
      {
        Header: "Promise Date",
        accessor: "promiseDate",
        Cell: ({ cell }) => {
            return (
              <>
                <h7>{cell.row.original.promiseDate && cell.row.original.promiseDate !== null ? formatDateTimeSeconds(cell.row.original.promiseDate) : "-"}</h7>
              </>
            );
        },
      },
      {
        Header: "Payment Date",
        accessor: "paymentDate",
        Cell: ({ cell }) => {
            return (
              <>
                <h7>{cell.row.original.paymentDate && cell.row.original.paymentDate !== 'null' ? formatDateTimeSeconds(cell.row.original.paymentDate) : "-"}</h7>
              </>
            );
        },
      },
      {
        Header: "Remarks",
        accessor: "remark",
        Cell: ({ cell }) => <span style={{ fontWeight: "600" }}>{cell.value || "-"}</span>,
      },             
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <>
              <h7>{cell.row.original.createdAt && cell.row.original.createdAt !== null ? formatDateTimeSeconds(cell.row.original.createdAt) : "-"}</h7>
            </>
          );
        },
      },  
    ],
    [pageIndex,pageSize]
  );
  
  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="orderListDataFooter">
        <div className="accordion-body">
          {merchantUserData && merchantUserData.length == 0 ? (
            <p style={{ fontSize: 22, marginTop: 20, fontWeight: "600" }}>
              No Data Found
            </p>
          ) : (
            <Table 
              columns={columns} 
              data={merchantUserData} 
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
              totalRecords={totalRecords}
              handleGotoPage={handleGotoPage}
              errorMessage={errorMessage} 
              handleSort={handleSort}
              sortBy={sortBy}
              orderBy={orderBy}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionDetails;