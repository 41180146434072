import React, { useState } from "react";
import "../css/makeTable.css";
import MerchantInnerDetails from "./Merchant/MerchantInnerDetails";
import MerchantCreditReport from "./Merchant/creditReport";
import MerchantRepaymentDetails from "./Merchant/MerchantRepaymentDetails";
import MerchantApiLogsDetails from "./Merchant/apiLogsDetails";
import MerchantsOrder from "./Merchant/MerchantsOrder";
import BankStatementReport from "./Merchant/BankStatementReport";
import FmiDetails from "./Merchant/FmiDetails.js";
import CollectionDetails from "./Merchant/collectionDetails.js";

function MerchantDetails() {
  const [activeTab, setActiveTab] = useState("home");
  const userRole = localStorage.getItem("USER_ROLE");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {(userRole === "Admin" || userRole === "Operation" || userRole === "Sales") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "home" ? "active" : ""}`}
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected={activeTab === "home"}
              onClick={() => handleTabClick("home")}
            >
              Merchant Details
            </button>
          </li>
        )}
        {(userRole === "Admin" || userRole === "Operation") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected={activeTab === "profile"}
              onClick={() => handleTabClick("profile")}
            >
              Credit
            </button>
          </li>
        )}
        {(userRole === "Admin" || userRole === "Operation" || userRole === "Sales") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "orders" ? "active" : ""}`}
              id="orders-tab"
              data-bs-toggle="tab"
              data-bs-target="#orders"
              type="button"
              role="tab"
              aria-controls="orders"
              aria-selected={activeTab === "orders"}
              onClick={() => handleTabClick("orders")}
            >
              Orders
            </button>
          </li>
        )}
        {(userRole === "Admin" || userRole === "Operation") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "fmi" ? "active" : ""}`}
              id="fmi-tab"
              data-bs-toggle="tab"
              data-bs-target="#fmi"
              type="button"
              role="tab"
              aria-controls="fmi"
              aria-selected={activeTab === "fmi"}
              onClick={() => handleTabClick("fmi")}
            >
              FMI
            </button>
          </li>
        )}
        {(userRole === "Admin" || userRole === "Operation" || userRole === "Sales") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "contact" ? "active" : ""}`}
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected={activeTab === "contact"}
              onClick={() => handleTabClick("contact")}
            >
              Repayment
            </button>
          </li>
        )}
        {(userRole === "Admin" || userRole === "Operation") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "api" ? "active" : ""}`}
              id="api-tab"
              data-bs-toggle="tab"
              data-bs-target="#api"
              type="button"
              role="tab"
              aria-controls="api"
              aria-selected={activeTab === "api"}
              onClick={() => handleTabClick("api")}
            >
              API Logs
            </button>
          </li>
        )}
         {(userRole === "Admin" || userRole === "Operation") && (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "collection" ? "active" : ""}`}
              id="collection-tab"
              data-bs-toggle="tab"
              data-bs-target="#collection"
              type="button"
              role="tab"
              aria-controls="collection"
              aria-selected={activeTab === "collection"}
              onClick={() => handleTabClick("collection")}
            >
              Collection
            </button>
          </li>
        )}
      </ul>
      <div>
        <p></p>
      </div>
      <div className="tab-content" id="myTabContent">
        {activeTab === "home" && (
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <MerchantInnerDetails />
          </div>
        )}
        {activeTab === "profile" && (
          <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <MerchantCreditReport />
          </div>
        )}
        {activeTab === "orders" && (
         <MerchantsOrder />
        )}
        {activeTab === "contact" && (
          <div className="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <MerchantRepaymentDetails />
          </div>
        )}
        {activeTab === "api" && (
          <div className="tab-pane fade show active" id="api" role="tabpanel" aria-labelledby="api-tab">
            <MerchantApiLogsDetails />
          </div>
        )}
        {activeTab === "fmi" && (
          <div className="tab-pane fade show active" id="fmi" role="tabpanel" aria-labelledby="fmi-tab">
            <FmiDetails />
          </div>
        )}
        {activeTab === "collection" && (
          <div className="tab-pane fade show active" id="collection" role="tabpanel" aria-labelledby="collection-tab">
            <CollectionDetails />
          </div>
        )}
      </div>
    </div>
  );
}

export default MerchantDetails;
