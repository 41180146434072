import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { FaPlusCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { getData, postData } from "../store/utils";
import { useDebounce } from "use-debounce";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useEffect } from "react";
import Loader from "../component/Loader";
import Swal from "sweetalert2";

const InventoryModalForm = ({ closeModal, updateOrderStatus, selectedRow }) => {
  const userInfo = JSON.parse(localStorage.getItem("USER_DATA"));
  const [itemListData, setItemListData] = useState([]);
  const [itemWarehouseListData, setItemWarehouseListData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTextWarehouse, setSearchTextWarehouse] = useState("");
  const [searchValue] = useDebounce(searchText, 1000);
  const [searchWarehouseValue] = useDebounce(searchTextWarehouse, 1000);
  const [formData, setFormData] = useState({
    items: [
      {
        visibleLable: selectedRow?.productId ? true : false,
        WareHousevisibleLable: selectedRow?.warehouseId ? true : false,
        productId: selectedRow?.productId || null,
        warehouseId: selectedRow?.warehouseId || null,
        productName: selectedRow?.productName || "",
        inventoryId: selectedRow?.inventoryId || "",
        warehouseName: selectedRow?.warehouseName || "",
        quantity: selectedRow?.quantity || "",
        distributorId: selectedRow?.distributorId || null,
        distributorProductId: selectedRow?.distributorProductId || null,
        brandName: selectedRow?.brand || "",
        subCategory: selectedRow?.subCategory,
        name: selectedRow?.name ? selectedRow?.name : selectedRow?.firstName + selectedRow?.lastName,
        categoryName: selectedRow?.productCategory,
        subCategory: selectedRow?.subCategory,
        color: selectedRow?.color,
        size: selectedRow?.size,
        createdBy: "23" || null,
      },
    ],
  });

  async function getProductList(typeValue) {
    setIsLoadingOn(true);
    try {
      const tempData = await getData(
        `electrician/searchForProduct?pageSize=100&page=1&search=${typeValue}`
      );

      if (
        tempData?.statusCode === 200 &&
        tempData?.data &&
        tempData?.data?.records
      ) {
        const tempDistData = tempData?.data?.records?.map((element) => {
          return element;
        });
        setItemListData(tempDistData);
      } else {
        console.error("Unexpected response format:", tempData);
        setItemListData([]); // Set to an empty array if response is not as expected
      }
    } catch (error) {
      console.error("Error fetching item list:", error);
      setItemListData([]); // Handle error case
    } finally {
      setIsLoadingOn(false); // Ensure loading is turned off
    }
  }

  async function getWarehouseList(typeValue) {
    setIsLoadingOn(true);
    try {
      const tempData = await getData(
        `warehouse/getWarehouse?warehouseName=${typeValue}`
      );

      if (tempData?.statusCode === 200 && tempData?.data?.data && tempData?.data?.data) {
        const tempDistData = tempData?.data?.data?.map((element) => {
          return element;
        });
        setItemWarehouseListData(tempDistData);
      } else {
        console.error("Unexpected response format:", tempData);
        setItemWarehouseListData([]); // Set to an empty array if response is not as expected
      }
    } catch (error) {
      console.error("Error fetching item list:", error);
      setItemWarehouseListData([]); // Handle error case
    } finally {
      setIsLoadingOn(false); // Ensure loading is turned off
    }
  }

  useEffect(() => {
    if (searchValue) {
      getProductList(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchWarehouseValue) {
      getWarehouseList(searchWarehouseValue);
    }
  }, [searchWarehouseValue]);

  const handleSearchChange = async (selected) => {
    setSearchText(selected);
  };

  const handleSearchWarehouseChange = async (selected) => {
    setSearchTextWarehouse(selected);
  };

  const handleSelectedId = (id, index, distributorProductId) => {
    // Validate itemListData
    if (!Array.isArray(itemListData)) {
      console.error("itemListData is not defined or is not an array");
      return;
    }
    // Find the matching data
    const filterData = itemListData.filter(
      (item) => item?.distributorProductId === distributorProductId
    );

    if (filterData?.length === 0) {
      console.log("No matching data found");
    } else {
      // Assuming filterData contains the selected item, and you want to set it in formData
      const selectedItem = filterData[0];
      setFormData((prevState) => {
        if (prevState?.items) {
          return {
            ...prevState,
            items: prevState?.items?.map((item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  visibleLable: true,
                  // WareHousevisibleLable: true,
                  brandName: selectedItem?.brand,
                  productId: selectedItem?.productId,
                  productName: selectedItem?.productName,
                  categoryName: selectedItem?.productCategory,

                  distributorId: parseInt(selectedItem?.distributorId), //getting errro 852
                  subCategory: selectedItem?.subCategory,
                  name: selectedItem?.name ? selectedItem?.name : selectedItem?.firstName + selectedItem?.lastName,
                  color: selectedItem?.color,
                  colorId: selectedItem?.colorId,
                  size: selectedItem?.size,
                  sizeId: selectedItem?.sizeId,

                  distributorProductId: selectedItem?.distributorProductId,
                  distributorId: selectedItem?.distributorId,
                  quantity: parseInt(selectedItem?.quantity),
                };
              }
              return item; // Return unchanged items
            }),
          };
        }
        return prevState; // If no orderQuotation, return unchanged state
      });
    }
  };

  const handleSelectedWarehouseId = (id, index, warehouseId) => {
    // Validate itemWarehouseListData
    if (!Array.isArray(itemWarehouseListData)) {
      console.error("itemWarehouseListData is not defined or is not an array");
      return;
    }
    // Find the matching data
    const filterData = itemWarehouseListData.filter(
      (item) => item?.warehouseId === warehouseId
    );

    if (filterData?.length === 0) {
      console.log("No matching data found");
    } else {
      // Assuming filterData contains the selected item, and you want to set it in formData
      const selectedItem = filterData[0];
      setFormData((prevState) => {
        if (prevState?.items) {
          return {
            ...prevState,
            items: prevState?.items?.map((item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  warehouseId: selectedItem?.warehouseId,
                };
              }

              return item; // Return unchanged items
            }),
          };
        }
        return prevState; // If no orderQuotation, return unchanged state
      });
    }
  };

  const handleUpdateRowChange = (event, index) => {
    const { name, value } = event?.target;
    setFormData((prevState) => {
      const updatedOrderQuotation = [...prevState?.items];
      updatedOrderQuotation[index] = {
        ...updatedOrderQuotation[index],
        [name]: value,
      };

      return {
        ...prevState,
        items: updatedOrderQuotation,
      };
    });
  };

  const totalRoiAddRow = () => {
    const newRow = { id: formData.items.length + 1 };
    const userInfo = JSON.parse(localStorage.getItem("USER_DATA"));
    console.log("userInfo++++", userInfo);
    setFormData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          visibleLable: false,
          WareHousevisibleLable: false,
          productId: null,
          productName: null,
          warehouseId: null,
          quantity: null,
          distributorId: null,
          distributorProductId: null,
          createdBy: userInfo?.employeeId,
        },
      ],
    }));
  };

  const totalRoiDeleteRow = (id, index) => {
    setFormData((prevState) => ({
      ...prevState,
      items: prevState.items.filter((row, innerIndex) => innerIndex !== index),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingOn(true);
    
    const res = await postData(`warehouse/addInventory`, formData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data added successfully!",
      });
      updateOrderStatus();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingOn(true);
    const res = await postData(`warehouse/updateInventory`, formData?.items[0]);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Updated successfully!",
      });
      updateOrderStatus();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="basicDetailsUser">
        <div className="d-flex mb-2">
          <div className="colorcodesec d-flex">
            <div className="prodcolor wd"></div>
            <div>Product</div>

          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="cateColor wd"></div>
            <div>Category</div>

          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="amtcolor wd"></div>
            <div>Amount</div>

          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="brandcolor wd"></div>
            <div>Brand</div>

          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="subcatcolor wd"></div>
            <div>Sub-category</div>
          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="distcolor wd"></div>
            <div>Distributor Name</div>
          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="color wd"></div>
            <div>Color</div>
          </div>
          <div className="colorcodesec d-flex ms-3">
            <div className="size wd"></div>
            <div>Size</div>
          </div>
        </div>
      </div>
      <div className="customrowChangeOrderDetilas">
        <div className="table-responsive inventoryModaldatatable">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>
                  <div className="d-flex justify-content-center">
                    <div>Action</div>
                    <div
                      className="proPlusIconGreen ms-2"
                      onClick={totalRoiAddRow}
                    >
                      <FaPlusCircle />
                    </div>
                  </div>
                </th>
                <th>
                  Product<span>*</span>
                </th>
                <th>
                  Warehouse Name<span>*</span>
                </th>
                <th>
                  Quantity<span>*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {formData?.items?.map((row, index) => {
                return (
                  <tr key={row.id} className="rowHeight">
                    <td
                      className="align-middle"
                      style={{ textAlign: "center" }}
                    >
                      {index + 1}.
                    </td>
                    <td className="align-middle">
                      <div
                        className=""
                        style={{ color: "red", fontSize: "20px" }}
                        onClick={() => totalRoiDeleteRow(row?.id, index)}
                      >
                        <MdDelete />
                      </div>
                    </td>
                    <td className="typheadsec align-middle">
                      {row?.visibleLable == true && (
                        <div
                          className="visibleData"
                          onClick={() =>
                            setFormData((prevState) => ({
                              ...prevState,
                              items: prevState?.items?.map((item, idx) =>
                                idx === index
                                  ? { ...item, visibleLable: false }
                                  : item
                              ),
                            }))
                          }
                        >
                          <div className="d-flex"><span className="prodcolor" title={row?.productName}>{row?.productName}</span></div>
                          <div className="d-flex"><span className="cateColor">{row?.categoryName}</span> <span className="subcatcolor ms-auto">{row?.subCategory}</span></div>
                          <div className="d-flex"><span className="brandcolor">{row?.brandName}</span> <span className="amtcolor ms-3">{row?.amount ? row?.amount : row?.mrpWithCashDiscount}</span> <span className="distcolor ms-auto">{row?.name}</span></div>
                          <div className="d-flex"><span className="size" title={row?.size}>{row?.size}</span> <span className="color ms-auto">{row?.color}</span></div>
                        </div>
                      )}
                      {row.visibleLable == false && (
                        <Typeahead
                          filterBy={[]}
                          id="custom-filtering-example"
                          labelKey={(option) =>
                            `${option?.productName} - ${option?.color} - ${option?.size
                            } - ${option?.brand} - ${option?.productCategory
                            } - ${option?.subCategory} -${option?.mrpWithCashDiscount
                            } -${option?.distributorId} - ${option?.name
                              ? option?.name
                              : option?.firstName + option?.lastName
                            }`
                          }
                          options={itemListData}
                          placeholder="Search by product name, brand or Category..."
                          onInputChange={handleSearchChange}
                          onChange={(selected) => {
                            if (selected && selected[0]) {
                              const selectedId = selected[0].id;
                              const distributorProductId =
                                selected[0]?.distributorProductId;
                              handleSelectedId(
                                selectedId,
                                index,
                                distributorProductId
                              );
                            }
                          }}
                          renderMenuItemChildren={(option) => {
                            const formattedPrice = option?.mrpWithCashDiscount
                              ? parseFloat(option?.mrpWithCashDiscount).toFixed(
                                2
                              )
                              : "0.00";
                            return (
                              <div className="typeaheadData">
                                <div className="d-flex">
                                  <div>
                                    <small className="prodcolor">
                                      {option?.productName
                                        ? option?.productName
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <div>
                                    <small className="cateColor">
                                      {option?.productCategory
                                        ? option?.productCategory
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="ms-auto">
                                    <small className="subcatcolor">
                                      {option?.subCategory
                                        ? option?.subCategory
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <small className="brandcolor">
                                      {option?.brand ? option?.brand : ""}
                                    </small>
                                  </div>
                                  <div>
                                    <small className="amtcolor ms-3">
                                      {formattedPrice ? formattedPrice : ""}
                                    </small>
                                  </div>
                                  <div className="ms-auto">
                                    <small className="distcolor">
                                      {option?.name
                                        ? option?.name
                                        : option?.firstName +
                                        " " +
                                        option?.lastName}
                                    </small>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <small className="size">
                                      {option?.size ? option?.size : ""}
                                    </small>
                                  </div>
                                  <div className="ms-auto">
                                    <small className="color">
                                      {option?.color ? option?.color : ""}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        />
                      )}
                    </td>
                    <td className="typheadsec align-middle">
                      {row?.WareHousevisibleLable == true && (
                        <div
                          className="visibleData"
                          onClick={() =>
                            setFormData((prevState) => ({
                              ...prevState,
                              items: prevState?.items?.map((item, idx) =>
                                idx === index
                              ? { ...item, WareHousevisibleLable: false} // Reset only warehouseName for the specific row
                              : item
                              ),
                            }))
                          }
                        >
                          <div className="d-flex">{row?.warehouseName}</div>
                        </div>
                      )}
                      {row.WareHousevisibleLable == false && (
                        <Typeahead
                          filterBy={[]}
                          id="custom-filtering-example"
                          labelKey={(option) => `${option?.warehouseName}`}
                          options={itemWarehouseListData}
                          placeholder="Search by warehouse name..."
                          onInputChange={handleSearchWarehouseChange}
                          onChange={(selected) => {
                            if (selected && selected[0]) {
                              const selectedId = selected[0].id;
                              const warehouseId = selected[0]?.warehouseId;
                              handleSelectedWarehouseId(
                                selectedId,
                                index,
                                warehouseId
                              );
                            }
                          }}
                          renderMenuItemChildren={(option) => {
                            return (
                              <div className="typeaheadData">
                                <div className="d-flex">
                                  <div>
                                    <small className="prodcolor">
                                      {option?.warehouseName
                                        ? option?.warehouseName
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        />
                      )}
                    </td>
                    <td className="quantitytd align-middle">
                      <input
                        type="number"
                        className="form-control AddingRowCss"
                        name="quantity"
                        value={row?.quantity}
                        onChange={(event) =>
                          handleUpdateRowChange(event, index)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-center mt-4">
        {
          !selectedRow?.productId ? 
          <button className="btn btn-success" onClick={handleSubmit}>
            Save
          </button>
           : 
           <button className="btn btn-success" onClick={handleUpdateSubmit}>
            Update
          </button>
      }          
        </div>
      </div>
    </>
  );
};

export default InventoryModalForm;
