import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../store/utils";
import { formatDate } from "../../component/common";
import Loader from "../../component/Loader";
import { FaRegFilePdf } from "react-icons/fa";

const FmiDetails = () => {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [merchantUserData, setMerchantUserData] = useState();
  const userId = searchParams.get("userId");
  const history = useHistory();
  const displayMerchantInfo = (user) => {
    console.log(user, "useruseruser++")
    const newTab = window.open(
      "/FundInvoiceDetails?invoiceId=" + user.invoiceId + "&distributorId=" + user.distributorId + "&referenceId=" + user.userId,
      "_blank"
    );
    if (!newTab) {
      history.push({
        pathname: "/OrderDetailsPage",
        state: { userId: user.userId, orderId: user.orderId },
      });
    }
  };

  const getorderDetails = async (userId) => {
    setIsLoadingOn(true);
    const tempData = await getData(`fundMyInvoice/getFMIData/${userId}`);
    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData?.data);
    }
  };

  useEffect(() => {
    if (userId) {
      getorderDetails(userId);
    }
  }, [userId]);
  return (
    <>
      <div>
        {isLoadingOn ? <Loader /> : ""}
        <div
          className="tab-pane fade show active"
          id="profile"
          role="tabpanel"
          aria-labelledby="orders-tab"
        >
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {merchantUserData &&
              merchantUserData.map((data, index) => (
                <div key={index} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      data-bs-target={`#panelsStayOpen-collapseOne${index + 1}`}
                      aria-controls={`panelsStayOpen-collapseOne${index + 1}`}
                    >
                      Order {index + 1}
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-collapseOne${index + 1}`}
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body">
                      <div className="bankDetailsMerchant">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-1">
                              <div className="merchantNameDetails">
                                <p>Order ID</p>
                                <h5
                                  onClick={() => {
                                    displayMerchantInfo(data);
                                  }}
                                  style={{ color: "blue", cursor: "pointer" }}
                                >
                                  {data?.orderId}
                                </h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Current Status</p>
                                <h5
                                  className={`badge badge-pill text-white ${data?.statusName === "Approved"
                                      ? "badge-success"
                                      : data?.statusName === "Processing"
                                        ? "badge-warning"
                                        : data?.statusName === "Pending"
                                          ? "badge-danger"
                                          : data?.statusName === "Delete"
                                            ? "badge-secondary"
                                            : "badge-primary"
                                    }`}
                                >
                                  {data?.statusName}
                                </h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Shop Name</p>
                                <h5>{data?.shopName}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Order Amount</p>
                                <h5>{data?.amount && `₹ ${(data?.amount)?.toFixed(2)}`}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Order Creation Date</p>
                                <h5>
                                  {data?.orderDate}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Repayment Date</p>
                                <h5>{data?.repaymentDate}</h5>
                              </div>
                            </div>

                            <div className="col-md-1">
                              <div className="merchantNameDetails">
                                <p>Outstanding</p>
                                <h5>{data?.outstandingAmount ? `₹ ${(data?.outstandingAmount)?.toFixed(2)}` : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-1">
                              <div className="merchantNameDetails">
                                <p>Processing Fee</p>
                                <h5>{data?.processingFees ? data?.processingFees : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Invoice Charges</p>
                                <h5>{data?.invoiceCharges ? data?.invoiceCharges : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Late Charges</p>
                                <h5>{(data?.extraLateCharges || data?.dailyLateCharges) ? (data?.extraLateCharges + data?.dailyLateCharges) : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Payment Status</p>
                                <h5>{data?.paymentStatusName ? data?.paymentStatusName : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>DPD Days</p>
                                <h5>{data?.DPD ? data?.DPD : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="merchantNameDetails">
                                <p>Total Repayment Amount</p>
                                <h5>{data?.totalRepaymentAmount ? `₹ ${(data?.totalRepaymentAmount)?.toFixed(2)}` : "-"}</h5>
                              </div>
                            </div>

                            <div className="col-md-1">
                              <div className="merchantNameDetails">
                                <p>Invoice</p>
                                <h5>
                                  {data?.invoicePath ? (
                                    <button style={{ fontSize: 22, color: "#6E12F9", border: "none", background: "none", cursor: "pointer"}}
                                      onClick={() => window.open(data.invoicePath, "_blank")}
                                    >
                                      <FaRegFilePdf />
                                    </button>
                                  ) : (
                                    "-"
                                  )}
                                </h5>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            {merchantUserData && merchantUserData.length == 0 && <p style={{ fontSize: 22, marginTop: 20, fontWeight: "600" }}>No Data Found</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FmiDetails;