import React, { useEffect, useState, useRef, useMemo } from "react";
import { FaFileCsv } from "react-icons/fa6";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDebounce } from 'use-debounce';
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Import CSS for Typeahead
import "../../css/makeTable.css";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination,
    useSortBy,
} from "react-table";
import Select from "react-select";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import sampleCSVFile from "../../CSVSample/OrderDetailsData.csv";
import { OrderListTable } from "../../store/tableConstant";
import * as XLSX from "xlsx";
import { processOnData } from "../DataImport/DataImportProcess.js";
import TopShowMessage from "../../component/TopShowMessage";
import Modal from "react-modal";
import { FaPlusCircle, FaEye, FaTrash } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import {
    FaRegTimesCircle,
    FaRegEye,
    FaSave,
    FaRegFilePdf,
} from "react-icons/fa";
import Swal from "sweetalert2";
import { formatDate } from "../../component/common.js";
import TypeHeadTable from "../../component/TypeHeadTable.js";
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";


const OrderDetails = () => {
    const customStylesDistributor = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            overflow: "none",
            padding: "0px",
            minWidth: "40%",
            padding: 20,
        },
    };

    const customStylesDisbursement = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            overflow: "none",
            padding: "0px",
            minWidth: "25%",
            padding: 20,
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: '20px'
        },
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        zIndex: 1010,
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    };

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            overflow: "none",
        },
        control: (provided) => ({
            ...provided,
            border: "none", // Remove border
            boxShadow: "none", // Remove the box shadow
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: "none", // Remove the dropdown arrow
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none", // Remove the separator line
        }),
        menu: (provided, state) => ({
            ...provided,
            width: 150, // Adjust as needed
        }),
    };

    // const userId = props && props.location.state && props.location.state.userId;
    // const orderId = props && props.location.state && props.location.state.orderId;
    const userRole = localStorage.getItem("USER_ROLE");
    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get("userId");
    const orderId = searchParams.get("orderId");
    const [merchantUserData, setMerchantUserData] = useState();
    const [orderImageData, setOrderImageData] = useState();
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [showTopMessage, setShowTopMessage] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [userInvoiceData, setUserInvoiceData] = useState();
    const [modalIsDisbursementApproval, setModalIsDisbursementApproval] = React.useState(false);
    const [disbursementApproval, setDisbursementApproval] = useState();
    const [approvalStatus, setApprovalStatus] = useState("Pending");
    const [invoiceUserData, setInvoiceUserData] = useState();
    const [modalIsQuotationOpen, setModalIsQuotationOpen] = useState(false);
    const [modalIsDisbursement, setModalIsDisbursement] = useState(false);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [agreementUpdateModal, setAgreementUpdateModal] = useState(false);
    const [distributorUserData, setDistributorUserData] = useState([]);
    const [productCategoryData, setProductCategoryData] = useState([]);
    const [viewOrderImageModal, setViewOrderImageModal] = useState();
    const [totalRoiRows, setTotalRoiRows] = useState([{ id: 1 }]);
    const [errors, setErrors] = useState({});
    const [selectedDistributor, setSelectedDistributor] = useState();
    const [selectedProductList, setselectedProductList] = useState();
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);
    const [brandListData, setBrandListData] = useState();
    const [invoiceDetailsData, setInvoiceDetailsData] = useState();
    const [distributorResponseData, setDistributorResponseData] = useState();
    const [invoiceDistributorData, setInvoiceDistributorData] = useState([]);
    const [selectedDistributorName, setSelectedDistributorName] = useState(null);
    const [selectedProductName, setSectedProductName] = useState(null);
    const [programData, setProgramData] = useState([]);
    const [disbursementInvoiceId, setDisbursementInvoiceId] = useState();
    const [itemListData, setItemListData] = useState([])
    const [checkFiles, setCheckFiles] = useState([]);
    const [fileInputs, setFileInputs] = useState([{ id: 1 }]);
    const [disbusApproval, setDisbusApproval] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchValue] = useDebounce(searchText, 1000);

    const [visibleItem, setVisibleItem] = useState("showInputData");
    const [showProductName, setShowroductName] = useState('')
    const [showBrandName, setShowBrandName] = useState('')
    const [showProductCategroy, setShowProductCategroy] = useState('')
    const [showSubCategory, setShowSubCategory] = useState('')
    const [showMrpWithCashDiscount, setShowMrpWithCashDiscount] = useState('')
    const [showDistributorName, setShowDistributorName] = useState('')
    const [showName, setShowName] = useState('')
    const [showImage, setShowImage] = useState(true);
    const [viewOnlyData, setViewOnlyData] = useState([])
    const [wareHouseData, setWareHouseData] = useState([]);
    const [brandId, setBrandId] = useState('')
    const [brandData, setBrandData] = useState([]);
    const [files, setFiles] = useState([]);
    const [agreementFileBase64, setAgreementFileBase64] = useState("");
    const [formData, setFormData] = useState({
        orderQuotation: [
            {
                brandName: null,
                item: null,
                visibleLable: false,
                categoryName: null,
                quantity: null,
                rate: null,
                sGST: "9",
                cGST: "9",
                totalAmount: null,
                // mrp: null,
                distributorId: null,
                brandId: null,
                productCategoryId: null,
                productCategory: null,
                buyingPricePerItem: null,
                totalBuyingAmount: null,
                totalSellingAmount: null,
                margin: null,
                listingPrice: null,
                promotionalDiscount: null,
                discountToMerchant: null,
                discountToDaqi: null,
                isGstApplicable: 0,
            },
        ],
    });

    const [formDistributorData, setFormDistributorData] = useState({
        invoiceNumber: "",
        invoiceAmount: "",
        invoiceDate: "",
        invoiceFile: "",
        invoiceFileBase64: "",
    });
    const [formDisbursementData, setFormDisbursementData] = useState({
        disbursedDate: "",
        utr: "",
        disbursementAmount: "",
        disbursementInvoiceId: "",
        disbursementDistributor: "",
        toDistributorDisbursementAmount: "",
        totalDisbursementAmount: "",
        lenderName: "",
    });

    const [filterBy, setFilterBy] = useState('callback');

    const handleChange = (e) => {
        const { name, files } = e?.target;
        if (name === "invoiceFile" && files?.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64String = reader.result.replace(
                    "data:application/pdf;base64,",
                    ""
                );
                if (base64String) {
                    getFileInvoiceData(base64String);
                }
                setFormDistributorData({
                    ...formDistributorData,
                    invoiceFileBase64: base64String,
                });
            };
        } else {
            setFormDistributorData({
                ...formDistributorData,
                [name]: e?.target?.value,
            });
        }
    };

    const handleDisbursedChange = (e) => {
        const { name } = e?.target;
        setFormDisbursementData({
            ...formDisbursementData,
            [name]: e?.target?.value,
        });
    };

    const updateDisbursementApproval = async () => {
        let filterData = {
            orderId: orderId,
            userId: userId,
            isDisbursementApproved: approvalStatus,
        };
        setIsLoadingOn(true);
        const tempData = await postData("order/updateDisbursementApproval", filterData);
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setDisbursementApproval(false);
            setShowTopMessage(false);
            closeModal();
        }
        else {
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    };

    const updatewareHouseData = async () => {
        setIsLoadingOn(true);
        try {
            const tempData = await getData("warehouse/getWarehouse");
            setIsLoadingOn(false);
            if (tempData.statusCode === 200) {
                const fetchedData = tempData.data.data || [];
                const tempDistData = fetchedData.map((element) => ({
                    value: element.warehouseId,
                    label: element.warehouseName,
                  }));
                setWareHouseData(tempDistData);
                setShowTopMessage(false);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: tempData?.data?.errors?.msg || "Something went wrong!!!",
                });
            }
        } catch (error) {
            setIsLoadingOn(false);
            console.error("Error fetching warehouse data:", error);
            Swal.fire({
                title: "Error!",
                text: "Failed to fetch warehouse data. Please try again.",
            });
        }
    };

    const getBrandData = async () => {
        setIsLoadingOn(true);
        try {
            const tempData = await getData("users/getBrands/1");
            setIsLoadingOn(false);

            if (tempData.statusCode === 200) {
                const fetchedData = tempData.data.records || [];
                const tempDistData = fetchedData.map((element) => ({
                    value: element.id,
                    label: element.name,
                  }));
                setBrandData(tempDistData);
                setShowTopMessage(false);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: tempData?.data?.errors?.msg || "Something went wrong!!!",
                });
            }
        } catch (error) {
            setIsLoadingOn(false);
            console.error("Error fetching brand data:", error);
            Swal.fire({
                title: "Error!",
                text: "Failed to fetch brand data. Please try again.",
            });
        }
    };

    const handleUpdateDisbursedInvoice = async (e) => {
        e.preventDefault();
        if (formDisbursementData?.disbursementAmount >= 50000 && checkFiles?.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please upload at least 1 and up to 5 files for disbursement amount is greater than or equal to 50000',
            });
            return;
        }

        if (formDisbursementData) {
            const checkDocument = checkFiles.map(file => file?.base64);
            let tempDataSend = {
                invoiceId: disbursementInvoiceId,
                utr: formDisbursementData?.utr,
                disbursedDate: formDisbursementData?.disbursedDate,
                checkDocument: checkDocument,
                tableReference: "ORDER",
            };
            setIsLoadingOn(true);
            const tempData = await postData(`loan/setDisbursement`, tempDataSend);
            setIsLoadingOn(false);
            if (tempData.statusCode == 200) {
                getDetailsDataDistributor(orderId);
                setModalIsDisbursement(false);
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Data Updated successfully!",
                });
            }
        }
    };

    const handleFileChange = (e, inputId) => {
        const selectedFiles = Array.from(e?.target?.files);
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

        if (selectedFiles?.length + checkFiles?.length > 5) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You can upload a maximum of 5 files only.',
            });
            return;
        }

        const invalidFiles = selectedFiles.filter((file) => !allowedTypes.includes(file?.type));
        if (invalidFiles?.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please select valid file formats (.pdf, .jpg, .jpeg, .png)',
            });
            e.target.value = '';
            return;
        }

        const promises = selectedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader?.result.split(',')[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises)
            .then((base64Files) => {
                setCheckFiles((prevFiles) => [...prevFiles, ...base64Files.map((base64) => ({ inputId, base64 })),]);
            })
            .catch((error) => {
                console.error('Error reading file:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'An error occurred while reading the file',
                });
            });
    };

    const handleAddFileInput = () => {
        if (fileInputs.length < 5) {
            setFileInputs((prevInputs) => [
                ...prevInputs,
                { id: prevInputs[prevInputs?.length - 1].id + 1 },
            ]);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You can only upload a maximum of 5 files.',
            });
        }
    };

    const handleRemoveFileInput = (inputId) => {
        // Remove the input field
        setFileInputs((prevInputs) =>
            prevInputs.filter((input) => input?.id !== inputId)
        );

        // Remove the corresponding files associated with the inputId
        setCheckFiles((prevFiles) =>
            prevFiles.filter((file) => file?.inputId !== inputId)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formDistributorData) {
            let tempDataSend = {
                userId: userId,
                referenceId: invoiceDetailsData?.orderId,
                referenceTable: "ORDER",
                distributorId: invoiceDetailsData?.distributorId,
                invoiceAmount: formDistributorData?.invoiceAmount,
                invoiceDate: formDistributorData?.invoiceDate,
                invoiceNumber: formDistributorData?.invoiceNumber,
                file: formDistributorData?.invoiceFileBase64,
            };
            setIsLoadingOn(true);
            const tempData = await postData(
                `documents/insertInvoiceWithoutExtraction`,
                tempDataSend
            );
            setIsLoadingOn(false);
            if (tempData.statusCode == 200) {
                setActionModalOpen(false);
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Data Updated successfully!",
                });
                getDetailsDataDistributor(orderId);
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: tempData?.data?.errors?.msg || "Somthing went wrong",
                });
            }
        }
    };

    const handleAgreementUpdateSubmit = async (e) => {
        e.preventDefault();
        if (formDistributorData) {
            let tempDataSend = {
                orderId: orderId,
                orderType: "ORDER",
                file: agreementFileBase64,
            };
            setIsLoadingOn(true);
            const tempData = await postData(
                `documents/updateAgreement`,
                tempDataSend
            );
            setIsLoadingOn(false);
            if (tempData.statusCode == 200) {
                setActionModalOpen(false);
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Data Updated successfully!",
                });
                getDetailsDataDistributor(orderId);
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: tempData?.data?.errors.msg || "Somthing went wrong",
                });
            }
        }
    };

    const handleFileInputChange = (event, key) => {
        const file = event?.target?.files[0];

        if (file) {
            const validFileType = "application/pdf";
            if (file.type !== validFileType) {
                Swal.fire({
                    icon: "error",
                    title: "Invalid File!",
                    text: "Please upload a valid PDF file.",
                });
                event.target.value = "";
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const base64URL = reader?.result;
                const pdfBase64 = base64URL.split(",")[1];
                setAgreementFileBase64(pdfBase64);
                setFormData((prevState) => ({
                    ...prevState,
                    [key]: pdfBase64,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectChange = async (key, selectedOption, index) => {
        const value =
            selectedOption && selectedOption?.value !== undefined
                ? selectedOption?.value
                : selectedOption;

        if (key === "productId") {
            await getProductData(value)
        }

        setFormData((prevState) => {
            if (prevState.orderQuotation) {
                return {
                    ...prevState,
                    orderQuotation: prevState?.orderQuotation.map((item, idx) => {
                        if (idx === index) {
                            return {
                                ...item,
                                [key]: selectedOption.label,
                                brandId: key === "brandName" ? value : item?.brandId,
                                productCategoryId:
                                    key === "categoryName" ? value : item?.productCategoryId,
                                distributorId:
                                    key === "distributorId" ? value : item?.distributorId,
                            };
                        }
                        return item;
                    }),
                };
            }
            return prevState;
        });
    };

    const handleUpdateRowChange = (event, index, field) => {
        const { name, value } = event?.target;
        setFormData((prevState) => {
            const updatedOrderQuotation = [...prevState?.orderQuotation];
            updatedOrderQuotation[index] = {
                ...updatedOrderQuotation[index],
                [name]: value,
            };

            if (name === "brandId") {
                setBrandId(value);
            }

            let {
                quantity,
                rate,
                sGST,
                cGST,
                buyingPricePerItem,
                listingPrice,
                isGstApplicable,
                promotionalDiscount,
            } = updatedOrderQuotation[index];
            let tempPromotionalDiscount = promotionalDiscount
                ? promotionalDiscount
                : 0;
            let tmpRate;
            let tempBuyingItem;
            let tempTotalAmount;
            let tempMargin;
            let totalbuying;
            if (Array.isArray(updatedOrderQuotation) && updatedOrderQuotation?.length > 0) {
                let data = updatedOrderQuotation[index];

                if (data) {
                    let tempdiscountToMerchant =
                        Number(data.discountToMerchant || 0) +
                        Number(tempPromotionalDiscount || 0);
                    tmpRate =
                        data.listingPrice - data.listingPrice * (tempdiscountToMerchant / 100);

                    tempBuyingItem =
                        data.listingPrice -
                        (data.listingPrice * (Number(data?.discountToDaqi) || 0)) / 100;
                    quantity = Number(quantity)
                    if (isGstApplicable) {
                        tempTotalAmount = (quantity * tmpRate) + ((quantity * tmpRate) * 0.18); //apply 18% GST on totalAmount if GST is applicable on specfic product
                        totalbuying = (tempBuyingItem * quantity) + ((quantity * tmpRate) * 0.18)
                    } else {
                        tempTotalAmount = quantity * tmpRate;
                        totalbuying = tempBuyingItem * quantity;
                    }

                    tempMargin = tempTotalAmount - totalbuying;

                    updatedOrderQuotation[index] = {
                        ...updatedOrderQuotation[index],
                        rate: tmpRate,
                        totalAmount: tempTotalAmount,
                        totalBuyingAmount: totalbuying.toFixed(2),
                        margin: tempMargin.toFixed(2),
                        discountToMerchant: data?.discountToMerchant,
                        discountToDaqi: data?.discountToDaqi,
                    };
                } else {
                    console.error(`Data at index ${index} is undefined.`);
                }
            } else {
                console.error("programData is not a valid array or is empty.");
            }

            return {
                ...prevState,
                orderQuotation: updatedOrderQuotation,
            };
        });
    };

    const totalRoiAddRow = () => {
        const newRow = { id: totalRoiRows?.length + 1 };
        setTotalRoiRows([...totalRoiRows, newRow]);
        setFormData((prevState) => ({
            ...prevState,
            orderQuotation: [
                ...prevState.orderQuotation,
                {
                    brandName: null,
                    visibleLable: false,
                    productCategory: null,
                    item: null,
                    categoryName: null,
                    quantity: null,
                    rate: null,
                    sGST: "9",
                    cGST: "9",
                    totalAmount: null,
                    distributorId: null,
                    brandId: null,
                    productCategoryId: null,
                    // buyingPricePerItem: null,
                    totalBuyingAmount: null,
                    // totalSellingAmount: null,
                    margin: null,
                    listingPrice: null,
                    promotionalDiscount: null,
                    discountToMerchant: null,
                    discountToDaqi: null,
                    isGstApplicable: 0,
                },
            ],
        }));
        // handleShowData("showInputData");
    };

    const totalRoiDeleteRow = (id, index) => {
        const updatedRows = totalRoiRows.filter((row) => row?.id !== id);
        setTotalRoiRows(updatedRows);

        setFormData((prevState) => ({
            ...prevState,
            orderQuotation: prevState.orderQuotation.filter(
                (row, innerIndex) => innerIndex !== index
            ),
        }));
    };

    const viewImageOrder = (image) => {
        setViewOrderImageModal(image);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setModalIsQuotationOpen(false);
        setActionModalOpen(false);
        setDisbursementApproval(false);
        setModalIsDisbursementApproval(false);
        setModalIsDisbursement(false);
        setAgreementUpdateModal(false);
    };

    const handleOpenModalDetails = (item) => {
        if (item) {
            setFormDistributorData({
                invoiceNumber: item ? item?.invoiceNumber : "",
                invoiceAmount: item ? item?.invoiceAmount : "",
                invoiceDate: item ? item?.invoiceDate : "",
                invoiceFile: "",
                invoiceFileBase64: "",
            });
            setInvoiceDetailsData(item);
        }
        setActionModalOpen(true);
    };

    const handleAgreementUpdateDetails = (item) => {
        if (item) {
            // setFormDistributorData({
            //   invoiceNumber: item ? item.invoiceNumber : "",
            //   invoiceAmount: item ? item.invoiceAmount : "",
            //   invoiceDate: item ? item.invoiceDate : "",
            //   invoiceFile: "",
            //   invoiceFileBase64: "",
            // });
            // setInvoiceDetailsData(item);
        }
        setAgreementUpdateModal(true);
    };

    async function getOrderquotation() {
        setIsLoadingOn(true);
        const tempData = await getData(`order/getOrderDetailByOrderId/${orderId}`);
        setIsLoadingOn(false);
        if (tempData.statusCode == 200 && tempData?.data?.length > 0) {
            setViewOnlyData(tempData?.data)
            const selectedItem = tempData?.data || [];
            setFormData((prevState) => {
                return {
                    ...prevState,
                    orderQuotation: selectedItem.map((item) => {
                        let tempTotal = item?.amount;
                        if (item?.isGstApplicable) {
                            tempTotal = tempTotal + (tempTotal * 0.18);
                        }

                        return {
                            ...item,
                            visibleLable: true,
                            brandName: item?.brand,
                            item: item?.productName, // Getting error "10"
                            productName: item?.productName,
                            categoryName: item?.productCategory,
                            quantity: parseInt(item?.quantity, 10),
                            rate: item?.rate,
                            color: item?.color,
                            colorId: item?.colorId,
                            size: item?.size,
                            sizeId: item?.sizeId,
                            sGST: "9",
                            cGST: "9",
                            totalAmount: tempTotal,
                            productId: parseInt(item?.productId, 10), // Getting error 852 
                            distributorId: parseInt(item?.distributorId, 10), // Getting error 852
                            brandId: parseInt(item?.brandId, 10), // Getting error 18
                            productCategoryId: parseInt(item?.productCategoryId, 10), // Getting error 2
                            buyingPricePerItem: null,
                            totalBuyingAmount: item?.totalBuyingAmount ? item?.totalBuyingAmount : item.buyingTotalPrice,
                            totalSellingAmount: null,
                            margin: parseInt(item?.margin, 10),
                            listingPrice: item?.listingPrice,
                            promotionalDiscount: null,
                            discountToMerchant: item?.discountToMerchant,
                            discountToDaqi: item?.discountToDaqi,
                            isGstApplicable: item?.isGstApplicable,
                            subCategory: item?.subCategory,
                            mrpWithCashDiscount: item?.mrpWithCashDiscount,
                            name: item.name || `${item?.firstName} ${item?.lastName}`,
                        };
                    }),
                };

            });

        }
    }

    async function DistributorGetData() {
        setIsLoadingOn(true);
        const tempData = await getData("users/distributorUser/1");
        setIsLoadingOn(false);
        const tempDistData = [];
        if (tempData?.statusCode == 200) {
            for (let i = 0; i < tempData?.data?.records?.length; i++) {
                const element = tempData?.data?.records[i];
                tempDistData.push({
                    value: element?.id,
                    label: element?.name + "-" + element?.id,
                });
            }
            setDistributorUserData(tempDistData);
        }
    }

    // Custom filtering function
    // const filterByCallback = (option, { text }) => {
    //   return option.brand.toLowerCase().includes(text.toLowerCase()) ||
    //     option.productName.toLowerCase().includes(text.toLowerCase());
    // };

    // Fields for default filtering
    // const filterByFields = ['brand', 'productName', 'productCategory', 'subCategory'];

    // Define the getItemList function here
    async function getProductList(typeValue) {
        setIsLoadingOn(true);
        try {
            const tempData = await getData(`electrician/searchForProduct?pageSize=100&page=1&brandId=${brandId}&search=${typeValue}`);

            if (tempData?.statusCode === 200 && tempData?.data && tempData?.data?.records) {
                // const tempDistData = tempData.data.records.map((element) => ({
                //   value: element.id,
                //   brand: `${element.brand}`,
                //   productName: `${element.productName}`,
                //   productCategory: `${element.productCategory}`,
                //   subCategory: `${element.subCategory}`,
                //   data: element
                // }));
                const tempDistData = tempData?.data?.records?.map((element) => {
                    return element
                })
                setItemListData(tempDistData);
            } else {
                console.error("Unexpected response format:", tempData);
                setItemListData([]); // Set to an empty array if response is not as expected
            }
        } catch (error) {
            console.error("Error fetching item list:", error);
            setItemListData([]); // Handle error case
        } finally {
            setIsLoadingOn(false); // Ensure loading is turned off
        }
    }

    const handleSearchChange = async (selected) => {
        setSearchText(selected)
    };

    const handleShowData = () => {
        setVisibleItem('showInputData')
    }

    const handleSelectedId = (id, index, distributorProductId) => {
        // Validate itemListData
        if (!Array.isArray(itemListData)) {
            console.error("itemListData is not defined or is not an array");
            return;
        }
        // Find the matching data
        const filterData = itemListData.filter((item) => item?.distributorProductId === distributorProductId);
        

        if (filterData?.length === 0) {
            console.log("No matching data found");
        } else {
            // Assuming filterData contains the selected item, and you want to set it in formData
            const selectedItem = filterData[0];
            setFormData((prevState) => {
                if (prevState?.orderQuotation) {
                    return {
                        ...prevState,
                        orderQuotation: prevState?.orderQuotation?.map((item, idx) => {
                            if (idx === index) {
                                return {
                                    ...item,
                                    visibleLable: true,
                                    brandName: selectedItem?.brand,
                                    item: selectedItem?.productName,// getting error "10"
                                    productName: selectedItem?.productName,
                                    categoryName: selectedItem?.productCategory,
                                    quantity: parseInt(selectedItem?.quantity),
                                    rate: parseInt(selectedItem?.rate),
                                    color: selectedItem?.color,
                                    colorId: selectedItem?.colorId,
                                    size: selectedItem?.size,
                                    sizeId: selectedItem?.sizeId,
                                    sGST: "9",
                                    cGST: "9",
                                    totalAmount: selectedItem?.totalAmount,
                                    productId: parseInt(selectedItem?.productId), //getting errro 852
                                    distributorId: parseInt(selectedItem?.distributorId), //getting errro 852
                                    brandId: parseInt(selectedItem?.brandId), //getting errro 18
                                    productCategoryId: parseInt(selectedItem?.productCategoryId), //getting errro 2
                                    buyingPricePerItem: null,
                                    totalBuyingAmount: parseInt(selectedItem?.totalBuyingAmount),
                                    totalSellingAmount: null,
                                    margin: parseInt(selectedItem?.margin),
                                    listingPrice: selectedItem?.listingPrice,
                                    promotionalDiscount: null,
                                    discountToMerchant: selectedItem?.discountToMerchant,
                                    discountToDaqi: selectedItem?.discountToDaqi,
                                    isGstApplicable: selectedItem?.isGstApplicable,
                                    subCategory: selectedItem?.subCategory,
                                    mrpWithCashDiscount: selectedItem?.mrpWithCashDiscount,
                                    shopName: selectedItem?.shopName

                                };
                            }

                            return item; // Return unchanged items
                        }),
                    };
                }
                return prevState; // If no orderQuotation, return unchanged state
            });
        }
    };

    async function getProgramBrands(distributorId, brandId, productCategoryId) {
        setIsLoadingOn(true);
        // alert("Test");
        const tempData = await getData(
            `users/getProgramBrands/nothing?distributorId=${distributorId}&brandId=${brandId}&productCategoryId=${productCategoryId}`
        );
        setIsLoadingOn(false);
        return tempData;
    }
    async function getProductCategoryData(distributorId, brandId) {
        setIsLoadingOn(true);
        const tempData = await getData(
            `users/getProductCategoryForProgram/nothing?distributorId=${distributorId}&brandId=${brandId}`
        );
        setIsLoadingOn(false);
        const tempDistData = [];
        if (tempData?.statusCode === 200) {
            for (let i = 0; i < tempData?.data?.length; i++) {
                const element = tempData?.data[i];
                tempDistData.push({
                    value: element?.id,
                    label: element?.name,
                });
            }
            setProductCategoryData(tempDistData);
        } else {
            Swal.fire({
                icon: "error",
                title: "No Product CategoryId Data For This Distributor and Brand",
            });
        }
    }
    async function getBrandsData(distributorId) {
        setIsLoadingOn(true);
        const tempData = await getData(
            `users/getBrandForProgram/nothing?distributorId=${distributorId}`
        );

        setIsLoadingOn(false);
        const tempDistData = [];
        if (tempData?.statusCode === 200) {
            for (let i = 0; i < tempData?.data?.length; i++) {
                const element = tempData?.data[i];
                tempDistData.push({
                    value: element?.id,
                    label: element?.name,
                });
            }
            setBrandListData(tempDistData);
        }
    }

    async function getProductData(productId) {
        setIsLoadingOn(true);
        const tempData = await getData(
            `/electrician/getDistributorsByProduct/1?productId=${productId}`
        );
        setIsLoadingOn(false);
        const tempDistData = [];
        if (tempData?.statusCode === 200) {
            const tempDistData = tempData?.data?.productResult?.map((element) => ({
                value: element?.id,
                label: element?.brand,
                brand: element?.brand,
                productCategory: element?.productCategory,
                subCategory: element?.subCategory,
            }));

            setBrandListData(tempDistData);
        }
    }

    useEffect(() => {
        if (searchValue) {
            getProductList(searchValue);
        }
        // if (orderId) {
        //   getOrderquotation();
        // }
    }, [searchValue]);

    useEffect(() => {
        if (orderId) {
            getOrderquotation();
        }
    }, [orderId])

    const validateForm = () => {
        const errors = {};

        // if (!formData.orderQuotation.every((order) => order.distributorId)) {
        //   errors.distributorId = "Please Select Distributor";
        // }
        // if (!formData.orderQuotation.every((order) => order.brandName)) {
        //   errors.brandName = "Please Select Brand Name";
        // }
        // if (!formData.orderQuotation.every((order) => order.item)) {
        //   errors.item = "Please Enter Item";
        // }
        // if (!formData.orderQuotation.every((order) => order.categoryName)) {
        //   errors.categoryName = "Please Select Category";
        // }
        if (!formData.orderQuotation.every((order) => order?.quantity)) {
            errors.quantity = "Please Enter Quantity";
        }
        // if (!formData.orderQuotation.every((order) => order.rate)) {
        //   errors.rate = "Please Enter Rate";
        // }

        // if (!formData.orderQuotation.every((order) => order.totalBuyingAmount)) {
        //   errors.totalBuyingAmount = "Please Enter Total Buying Amount";
        // }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleShowDisbursementData = (item) => {
        setFormDisbursementData({
            disbursementDistributor: item ? item?.distributorName : "",
            disbursementInvoiceId: item ? item?.invoiceId : "",
            disbursementAmount: item ? item?.invoiceAmount : "",
            toDistributorDisbursementAmount: item ? item?.toDistributorDisbursementAmount : "",
            totalDisbursementAmount: item ? item?.totalDisbursementAmount : "",
            lenderName: item ? item?.lenderName : "",
        });
        setModalIsDisbursement(true);
    };

    const handelUpdateSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            let filterData = {
                orderId: orderId,
                userId: userId,
                orderQuotation: formData?.orderQuotation,
            };
            let notificationData = {
                title: "Daqi",
                body: `Dear ${merchantUserData?.shopName}, You received a Quotation. Kindly accept and raise the invoice. Thanks team Daqi`,
                userId: [userId],
            };
            setIsLoadingOn(true);
            try {

                const tempData = await postData(`users/insertOrderNew`, filterData);
                if (tempData?.statusCode == 200) {
                    getDetailsDataDistributor(orderId);
                    const notifydata = await postData(
                        `users/notification`,
                        notificationData
                    );

                    if (notifydata.statusCode == 200) {
                        console.log("NotifyData", notifydata.statusCode);
                    }
                    setShowTopMessage(true);
                    setTimeout(() => {
                        // setShowTopMessage(false);
                        window.location.reload();
                    }, 3000);
                    Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: "Data inserted successfully!",
                    }).then((result) => {
                        if (result?.isConfirmed) {
                            closeModal();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: tempData?.data?.errors.msg || "Failed to insert data.",
                    });
                }
            } catch (error) {
                setIsLoadingOn(false);
            }
            
        } else {
            console.log("Form validation failed.");
        }
    };

    async function merchantGetData() {
        setIsLoadingOn(true);
        const tempData = await getData(`users/getUserShopByOrderId/${orderId}`);
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setMerchantUserData(tempData?.data[0]);
            setUserInvoiceData(tempData?.data[0]?.document);
        }
    }


    const getDetailsDataDistributor = async (orderId) => {
        setIsLoadingOn(true);
        const res = await getData(`distributor/getOrdersDistributors/params?orderId=${orderId}`);
        setIsLoadingOn(false);
        if (res?.statusCode === 200) {
            const distributorData = res?.data?.map(item => ({
                ...item,
                statusName: merchantUserData?.statusName || null,
            }));
            setInvoiceDistributorData(distributorData);
            setDistributorResponseData(res?.data?.length);
            if (res?.data && res?.data?.length > 0) {
                setInvoiceUserData(res?.data[0]?.invoicePath || null);
                setDisbusApproval(res?.data[0]?.isDisbursementApproved != null ? res?.data[0]?.isDisbursementApproved : "Pending");
            }
        }
    };

    const formatter = new Intl.NumberFormat("en-IN");

    useEffect(() => {
        merchantGetData();
        getDetailsDataDistributor(orderId);
        updatewareHouseData();
        getBrandData();
    }, [orderId]);

    const getFileInvoiceData = async (fileData) => {
        let tempData = {
            file: fileData,
        };
        setIsLoadingOn(true);
        const res = await postData(`documents/invoiceData`, tempData);
        setIsLoadingOn(false);
        if (res.statusCode === 200) {
            let invoiceDetails = res.data[0];
            let formattedDate = new Date(invoiceDetails?.invoiceDate)
                .toISOString()
                .split("T")[0];
            setFormDistributorData((prevData) => ({
                ...prevData,
                invoiceDate: formattedDate,
                invoiceNumber: invoiceDetails?.invoiceNumber
                    ? invoiceDetails?.invoiceNumber
                    : invoiceDetailsData?.invoiceNumber,
            }));
        }
    };

    async function merchantOrderImage() {
        let tempDocument = {
            userId: userId,
            referenceId: orderId,
            tableReference: "ORDER",
        };
        setIsLoadingOn(true);
        const tempData = await postData(`documents/getDocuments`, tempDocument);
        setIsLoadingOn(false);
        if (tempData?.statusCode == 200) {
            setOrderImageData(tempData?.data);
        }
    }

    useEffect(() => {
        merchantOrderImage();
    }, []);

    const data = useMemo(
        () => [
            {
                distId: 1,
                name: "John Doe",
                age: 25,
                city: "New York",
                mobileNo: "9988776786",
                data1: "9988776786",
                action: "9988776786",
            },
        ],
        []
    );

    const openUploadQuotation = () => {
        setModalIsQuotationOpen(true);
    };

    const openDisbursementApproval = () => {
        setApprovalStatus(disbusApproval); // Reset to Pending when opening the modal
        setModalIsDisbursementApproval(true);
    };

    const expandTable = (e) => {
        e.preventDefault()
        setShowImage((prevShowImage) => !prevShowImage);
    }

    const columns = useMemo(
        () => [
            {
                Header: "Order Invoice Id",
                accessor: "orderInvoiceIds",
            },
            {
                Header: "Distributor Name",
                accessor: "distributorName",
            },
            {
                Header: "Invoice Number",
                accessor: "invoiceNumber",
            },
            {
                Header: "Invoice Amount",
                accessor: "invoiceAmount",
                Cell: ({ value }) => {
                    const numericValue = Number(value);
                    if (!isNaN(numericValue)) {
                        const roundedValue = numericValue.toFixed(2);
                        return formatter.format(roundedValue);
                    } else {
                        return value || "-";
                    }
                },
            },
            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                Cell: ({ cell }) => {
                    const invoiceDate = cell?.row?.original?.invoiceDate;
                    return <span>{invoiceDate && formatDate(invoiceDate)}</span>;
                },
            },
            {
                Header: "Disbursement Date",
                accessor: "disbursedDate",
                Cell: ({ cell }) => {
                    const disbursedDate = cell?.row?.original?.disbursedDate;
                    return <span>{disbursedDate && formatDate(disbursedDate)}</span>;
                },
            },
            {
                Header: "Document",
                accessor: "invoicePath",
                Cell: ({ row }) => {
                    let invoiceData = row?.original?.invoicePath;
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {invoiceData && (
                                <button
                                    style={{
                                        fontSize: 22,
                                        color: "#6E12F9",
                                        border: "none",
                                        background: "none",
                                        marginRight: "10px",
                                    }}
                                    onClick={() =>
                                        window.open(row?.original?.invoicePath, "_blank")
                                    }
                                >
                                    <FaRegFilePdf />
                                </button>
                            )}
                        </div>
                    );
                },
            },
            // {
            //   Header: "Actions",
            //   accessor: "filePath",
            //   Cell: ({ cell }) => {
            //     const file = cell.row.original.invoicePath;
            //     const disbursedDate = cell.row.original.disbursedDate;
            //     let status = cell.row.original.status;
            //     return (
            //       <>
            //         {disbursedDate ? (
            //           <div className="dibsursedTagShow">Disbursed</div>
            //         ) : (
            //           <div
            //             className="loanIdDownloadBtnDiv text-center"
            //             style={{ padding: "5px !important;" }}
            //           >
            //             {file ? (
            //               <>
            //                 {status && status == 37 || status && status == 30 ? (
            //                   <button
            //                     className="btn btn-primary mb-1"
            //                     onClick={() => {
            //                       handleShowDisbursementData(cell.row.original);
            //                       setDisbursementInvoiceId(
            //                         cell.row.original.invoiceId
            //                       );

            //                     }}
            //                   >
            //                     <span style={{ fontSize: 14 }}>Add Disbursement</span>
            //                   </button>
            //                 ) : null}
            //                 <button
            //                   className="btn-sm btn-warning"
            //                   onClick={() => {
            //                     if (
            //                       userRole === "Admin" ||
            //                       userRole === "Operation"
            //                     ) {
            //                       handleOpenModalDetails(cell.row.original);
            //                     }
            //                   }}
            //                 >
            //                   <span>Update Invoice {userRole}</span>
            //                 </button>
            //               </>
            //             ) : (
            //               <button
            //                 className={`btn-sm btn-primary`}
            //                 onClick={() => {
            //                   if (userRole === "Admin" || userRole === "Operation") {
            //                     handleOpenModalDetails(cell.row.original);
            //                   }
            //                 }}
            //               >
            //                 <span style={{ fontWeight: "500" }}>Add Invoice</span>
            //               </button>
            //             )}
            //           </div>
            //         )}
            //         {(userRole === "Admin" || userRole === "Operation") && cell?.row?.original?.agreement?.length > 0 && (
            //           <button
            //             className="btn-sm btn-warning"
            //             onClick={() => handleAgreementUpdateDetails(cell?.row?.original)}
            //           >
            //             <span>Update Agreement</span>
            //           </button>
            //         )}
            //       </>
            //     );
            //   },
            // },
            {
                Header: "Actions",
                accessor: "filePath",
                Cell: ({ cell }) => {
                    const file = cell?.row?.original?.invoicePath;
                    const disbursedDate = cell?.row?.original?.disbursedDate;
                    let status = cell?.row?.original?.status;
                    return (
                        <>
                            {disbursedDate ? (
                                <div className="dibsursedTagShow">Disbursed</div>
                            ) : (
                                <div
                                    className="loanIdDownloadBtnDiv text-center"
                                    style={{ padding: "5px !important;" }}
                                >
                                    {(file && !disbursedDate) && (
                                        <>
                                            <button
                                                className="btn btn-primary mb-1"
                                                onClick={() => {
                                                    handleShowDisbursementData(cell?.row?.original);
                                                    setDisbursementInvoiceId(
                                                        cell?.row?.original?.invoiceId
                                                    );

                                                }}
                                            >
                                                <span style={{ fontSize: 14 }}>Add Disbursement</span>
                                            </button>
                                            <button
                                                className="btn-sm btn-warning"
                                                onClick={() => {
                                                    if (
                                                        userRole === "Admin" ||
                                                        userRole === "Operation"
                                                    ) {
                                                        handleOpenModalDetails(cell?.row?.original);
                                                    }
                                                }}
                                            >
                                                <span>Update Invoice</span>
                                            </button>
                                        </>
                                    )}
                                    {!file && (
                                        <button
                                            className={"btn-sm btn-primary"}
                                            onClick={() => {
                                                if (userRole === "Admin" || userRole === "Operation") {
                                                    handleOpenModalDetails(cell?.row?.original);
                                                }
                                            }}
                                        >
                                            <span style={{ fontWeight: "500" }}>Add Invoice</span>
                                        </button>
                                    )}
                                </div>
                            )}
                            {(userRole === "Admin" || userRole === "Operation") && cell?.row?.original?.agreement?.length > 0 && (
                                <button
                                    className="btn-sm btn-warning"
                                    onClick={() => handleAgreementUpdateDetails(cell?.row?.original)}
                                >
                                    <span>Update Agreement</span>
                                </button>
                            )}
                        </>
                    );
                },
            },
            {
                Header: "Cheque Documents",
                accessor: "",
                Cell: ({ cell }) => {
                    const fileUrls = cell?.row?.original?.checkDocument;

                    return (
                        <div className="text-center">
                            {fileUrls && Array.isArray(fileUrls) && fileUrls?.length > 0 ? (
                                fileUrls.map((url, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        onClick={() => window.open(url, '_blank')}
                                        style={{ fontSize: 22, color: "#6E12F9", border: "none", background: "none", margin: "0 5px" }}
                                        title={`Download file ${index + 1} for ${cell?.row?.original?.distributorName}`}
                                    >
                                        <FaRegFilePdf />
                                    </button>
                                ))
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );

    const safeToFixed = (value, decimals = 2) => {
        // Ensure the value is a number or default to 0
        const numericValue = Number(value) || 0;
        return numericValue.toFixed(decimals);
    };
    return (
        <div>

            <div>
                <div className="row alignmentTable mb-3">
                    <div className="col-md-12 mt-3">
                        <div className="d-flex"><h5 style={{ marginBottom: 0 }}><b>Order Details</b></h5><h6 className="ms-auto"><b>Order Id - {orderId}</b></h6></div>
                    </div>
                    <div className="col-md-12">
                        <div className="brd">
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <div><b>Name :</b>	{merchantUserData && merchantUserData?.shopName}</div>
                                </div>
                                <div className="col-md-4">
                                    <div><b>Mobile :</b>	{merchantUserData && merchantUserData?.mobileNumber}</div>
                                </div>
                                <div className="col-md-4">
                                    <div><b>Mer. Ref No. :</b>	{merchantUserData && merchantUserData?.merchantReferenceNumber}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <div><b>Order Date :</b>	{merchantUserData && merchantUserData?.orderDate}</div>
                                </div>
                                <div className="col-md-4">
                                    <div><b>Order Status :</b>	{merchantUserData && (
                                        <span
                                            className={
                                                merchantUserData?.statusName == "Approved"
                                                    ? "text-success"
                                                    : merchantUserData?.statusName == "Processing"
                                                        ? "text-warning"
                                                        : "text-danger"
                                            }
                                        >
                                            {merchantUserData && merchantUserData?.statusName}
                                        </span>
                                    )}{" "}</div>
                                </div>
                                <div className="col-md-4">
                                    <div><b>Establishment Year :</b>	{merchantUserData && merchantUserData?.businessEstablishmentYear}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <div><b>FOS Name :</b>	{merchantUserData && merchantUserData?.fosName ? merchantUserData?.fosName : "Not Available"}</div>
                                </div>
                                <div className="col-md-8">
                                    <div><b>Address :</b>	{merchantUserData &&
                                        `${merchantUserData?.shopNumberAndBuildingName},
                    ${merchantUserData?.address1},
                    ${merchantUserData?.address2},
                    ${merchantUserData?.city},
                    ${merchantUserData?.state},
                    ${merchantUserData?.shopPinCode},
                     `}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className="col-md-2 text-end">
              <button
                className="btn btnAddCredit"
                onClick={() => {
                  setModalIsDisbursement(true);
                }}
              >
                Set Disbursement
              </button>
            </div> */}
                    {(invoiceUserData !== null && distributorResponseData > 0 && merchantUserData?.statusName == "Approved") ? (
                        <div className="col-md-2 text-end">
                            <button
                                style={{ fontSize: 12 }}
                                className="btn btnAddCredit"
                                onClick={() => {
                                    openDisbursementApproval();
                                }}
                            >
                                Disbursement Approval
                            </button>
                        </div>
                    ) : ""}

                    {merchantUserData && merchantUserData?.statusName == "Approved" ? (
                        ""
                    ) : (
                        <div className="col-md-2 text-end">
                            {/* <button
                  style={{ fontSize: 11 }}
                  className="btn btnAddCredit"
                  onClick={() => {
                    openUploadQuotation();
                  }}
                >
                  Upload Quotation
                </button> */}
                        </div>
                    )}
                </div>
            </div>

            <section className="orderDetailsSection mt-5">
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item acorrdiannew">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            // data-bs-toggle="collapse"
                                            // data-bs-target="#panelsStayOpen-collapseOne"
                                            aria-expanded="true"
                                        // aria-controls="panelsStayOpen-collapseOne"
                                        >
                                            Distributor Invoice Details
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseOne"
                                        className="accordion-collapse collapse show"
                                    >
                                        {invoiceDistributorData &&
                                            invoiceDistributorData?.length > 0 ? (
                                            <div className="accordion-body">
                                                <Table
                                                    columns={columns}
                                                    data={invoiceDistributorData}
                                                />
                                            </div>
                                        ) : (
                                            <h5 style={{ padding: 10 }}>No Data Found</h5>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className={`left-table  ${!showImage ? "col-md-7" : "col-md-12"}`}>
                            <div className="basicDetailsUser">
                                <div>
                                    <form>
                                        <div>
                                            <div className="d-flex mb-2">
                                                <div className="colorcodesec d-flex">
                                                    <div className="prodcolor wd"></div>
                                                    <div>Product</div>

                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="cateColor wd"></div>
                                                    <div>Category</div>

                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="amtcolor wd"></div>
                                                    <div>Amount</div>

                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="brandcolor wd"></div>
                                                    <div>Brand</div>

                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="subcatcolor wd"></div>
                                                    <div>Sub-category</div>
                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="distcolor wd"></div>
                                                    <div>Distributor Name</div>
                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="color wd"></div>
                                                    <div>Color</div>
                                                </div>
                                                <div className="colorcodesec d-flex ms-3">
                                                    <div className="size wd"></div>
                                                    <div>Size</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="customrowChangeOrderDetilas">
                                            <div className="expandButton">
                                                <button className="toggle-button" onClick={expandTable}>
                                                    {showImage ? <IoIosArrowDroprightCircle /> : <IoIosArrowDropleftCircle />}
                                                </button>
                                            </div>
                                            <div className="table-responsive">
                                                <table className={!(merchantUserData?.statusName === "Approved" || merchantUserData?.statusName === "Decline") ? "table table-bordered" : "table table-bordered notselect"}>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr.</th>
                                                            <th>
                                                                <div className="d-flex justify-content-center">
                                                                    <div>Action</div>
                                                                    <div
                                                                        className="proPlusIconGreen ms-2"
                                                                        onClick={totalRoiAddRow}
                                                                    >
                                                                        <FaPlusCircle />
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th>Ware House</th>
                                                            <th>Brand</th>
                                                            <th>Product<span>*</span></th>
                                                            <th>Quantity<span>*</span></th>
                                                            <th>GST<span>*</span></th>
                                                            <th>Listing Price</th>
                                                            <th>Rate</th>
                                                            <th>Total Amount</th>
                                                            <th title="Total Buying Amount">TBA</th>
                                                            <th>Margin</th>
                                                            <th>% to Merchant</th>
                                                            <th>% for Daqi</th>
                                                            <th>% Promo</th>
                                                        </tr>
                                                    </thead>
                                                    {(merchantUserData?.statusName === "Pending" || merchantUserData?.statusName === "Quotation Pending" || merchantUserData?.statusName === "Merchant Approval Pending") ?

                                                        <tbody>

                                                            {formData?.orderQuotation?.map((row, index) => {
                                                                const marginFixed = row?.margin
                                                                return (
                                                                    <tr key={row.id} className="rowHeight">
                                                                        <td className="align-middle" style={{ textAlign: "center" }}>{index + 1}.</td>
                                                                        <td className="align-middle">
                                                                            {/* {index === 0 ? (
                                                                                <div
                                                                                className="proPlusIconGreen"
                                                                                onClick={totalRoiAddRow}
                                                                                >
                                                                                <FaPlusCircle />
                                                                                </div>
                                                                            ) : ( */}
                                                                            <div
                                                                                className=""
                                                                                style={{ color: "red", fontSize: "20px" }}
                                                                                onClick={() => totalRoiDeleteRow(row?.id, index)}
                                                                            >
                                                                                <MdDelete />
                                                                            </div>
                                                                            {/* )} */}
                                                                        </td>
                                                                        <td className={errors?.warehouseId ? "border-danger align-middle" : "warehouseId align-middle"}>
                                                                            <Select
                                                                                className=""
                                                                                value={wareHouseData.find((wh) => wh.value === row.warehouseId) || ""}
                                                                                name="warehouseId"
                                                                                onChange={(selectedOption) => handleUpdateRowChange({ target: { name: "warehouseId", value: selectedOption?.value } }, index, "orderQuotation")}
                                                                                options={wareHouseData}
                                                                                placeholder="Select Warehouse"
                                                                                isSearchable
                                                                                styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    width: '200px',
                                                                                    border: '1px solid #f5f5f5',
                                                                                    borderRadius: '4px',
                                                                                }),
                                                                                placeholder: (provided) => ({
                                                                                    ...provided,
                                                                                    color: "#aaa",
                                                                                }),
                                                                                singleValue: (provided) => ({
                                                                                    ...provided,
                                                                                    color: "#000",
                                                                                }),
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className={errors?.brandId ? "border-danger align-middle" : "brandtd align-middle"}>
                                                                            <Select
                                                                                className=""
                                                                                value={brandData.find((brand) => brand.value === row.brandId) || ""}
                                                                                name="brandId"
                                                                                onChange={(selectedOption) => handleUpdateRowChange({ target: { name: "brandId", value: selectedOption?.value } }, index, "orderQuotation")}
                                                                                options={brandData}
                                                                                placeholder="Select Brand"
                                                                                isSearchable
                                                                                styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    width: '200px',
                                                                                    border: '1px solid #f5f5f5',
                                                                                    borderRadius: '4px',
                                                                                }),
                                                                                placeholder: (provided) => ({
                                                                                    ...provided,
                                                                                    color: "#aaa",
                                                                                }),
                                                                                singleValue: (provided) => ({
                                                                                    ...provided,
                                                                                    color: "#000",
                                                                                }),
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="typheadsec align-middle">
                                                                            {row?.visibleLable == true && (
                                                                                <div className="visibleData"
                                                                                    onClick={() =>
                                                                                        setFormData((prevState) => ({
                                                                                            ...prevState,
                                                                                            orderQuotation: prevState?.orderQuotation?.map((item, idx) =>
                                                                                                idx === index ? { ...item, visibleLable: false } : item
                                                                                            ),
                                                                                        }))
                                                                                    }
                                                                                >
                                                                                    <div className="d-flex"><span className="prodcolor" title={row?.productName}>{row?.productName}</span></div>
                                                                                    <div className="d-flex"><span className="cateColor">{row?.categoryName}</span> <span className="subcatcolor ms-auto">{row?.subCategory}</span></div>
                                                                                    <div className="d-flex"><span className="brandcolor">{row?.brandName}</span> <span className="amtcolor ms-3">{row?.amount ? row?.amount : row?.mrpWithCashDiscount}</span> <span className="distcolor ms-auto">{row?.shopName}</span></div>
                                                                                    <div className="d-flex"><span className="size" title={row?.size}>{row?.size}</span> <span className="color ms-auto">{row?.color}</span></div>
                                                                                </div>
                                                                            )}
                                                                            {row.visibleLable == false && (
                                                                                <Typeahead
                                                                                    filterBy={[]}
                                                                                    id="custom-filtering-example"
                                                                                    labelKey={(option) => `${option?.productName} - ${option?.color} - ${option?.size} - ${option?.brand} - ${option?.productCategory} - ${option?.subCategory} -${option?.mrpWithCashDiscount} -${option?.distributorId} - ${option?.shopName}`}
                                                                                    options={itemListData}
                                                                                    placeholder="Search by product name, brand or Category..."
                                                                                    onInputChange={handleSearchChange}
                                                                                    onChange={(selected) => {
                                                                                        if (selected && selected[0]) {
                                                                                            const selectedId = selected[0].id;
                                                                                            const distributorProductId = selected[0]?.distributorProductId;
                                                                                            handleSelectedId(selectedId, index, distributorProductId)
                                                                                        }
                                                                                    }}
                                                                                    renderMenuItemChildren={(option) => {
                                                                                        const formattedPrice = option?.mrpWithCashDiscount
                                                                                            ? parseFloat(option?.mrpWithCashDiscount).toFixed(2)
                                                                                            : "0.00";
                                                                                        return (
                                                                                            <div className="typeaheadData">
                                                                                                <div className="d-flex">
                                                                                                    <div><small className="prodcolor">{option?.productName ? option?.productName : ''}</small></div>
                                                                                                </div>

                                                                                                <div className="d-flex">
                                                                                                    <div><small className="cateColor">{option?.productCategory ? option?.productCategory : ''}</small></div>
                                                                                                    <div className="ms-auto"><small className="subcatcolor">{option?.subCategory ? option?.subCategory : ''}</small></div>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <div><small className="brandcolor">{option?.brand ? option?.brand : ''}</small></div>
                                                                                                    <div><small className="amtcolor ms-3">{formattedPrice ? formattedPrice : ''}</small></div>
                                                                                                    {/*<div className="ms-3"><small className="distcolor">{option.distributorId ? option.distributorId : ''}</small></div> */}
                                                                                                    <div className="ms-auto"><small className="distcolor">{option?.shopName ? option?.shopName : ''}</small></div>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <div><small className="size">{option?.size ? option?.size : ''}</small></div>
                                                                                                    <div className="ms-auto"><small className="color">{option?.color ? option?.color : ''}</small></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {errors?.distributorId && (
                                                                                <div className="text-danger">
                                                                                    {errors?.distributorId}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className={errors?.quantity ? "border-danger align-middle" : "quantitytd align-middle"}>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCss"
                                                                                name="quantity"
                                                                                value={row?.quantity}
                                                                                onChange={(event) =>
                                                                                    handleUpdateRowChange(
                                                                                        event,
                                                                                        index,
                                                                                        "orderQuotation"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="align-middle" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                            <input
                                                                                type="checkbox"
                                                                                name="isGstApplicable"
                                                                                checked={row?.isGstApplicable === 1}
                                                                                onChange={(event) => {
                                                                                    const value = event?.target?.checked ? 1 : 0;
                                                                                    handleUpdateRowChange(
                                                                                        { target: { name: "isGstApplicable", value } },
                                                                                        index,
                                                                                        "orderQuotation"
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCss"
                                                                                name="listingPrice"
                                                                                value={row?.listingPrice}
                                                                                readOnly
                                                                                onChange={(event) =>
                                                                                    handleUpdateRowChange(
                                                                                        event,
                                                                                        index,
                                                                                        "orderQuotation"
                                                                                    )
                                                                                }
                                                                            />
                                                                            {errors?.listingPrice && (
                                                                                <div className="text-danger">
                                                                                    {errors?.listingPrice}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCssReadonly"
                                                                                name="rate"
                                                                                value={row.rate}
                                                                                readOnly
                                                                            />
                                                                            {errors?.rate && (
                                                                                <div className="text-danger">{errors?.rate}</div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCssReadonly"
                                                                                name="totalAmount"
                                                                                value={row?.totalAmount}
                                                                                readOnly
                                                                            />
                                                                            {errors?.totalAmount && (
                                                                                <div className="text-danger">
                                                                                    {errors?.totalAmount}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCssReadonly"
                                                                                name="totalBuyingAmount"
                                                                                value={row?.totalBuyingAmount ? row?.totalBuyingAmount : row?.buyingTotalPrice}
                                                                                readOnly
                                                                            />
                                                                            {errors?.totalBuyingAmount && (
                                                                                <div className="text-danger">
                                                                                    {errors?.totalBuyingAmount}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCssReadonly"
                                                                                name="margin"
                                                                                value={marginFixed}
                                                                                readOnly
                                                                            />
                                                                            {errors?.margin && (
                                                                                <div className="text-danger">{errors?.margin}</div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCssReadonly"
                                                                                name="discountToMerchant"
                                                                                value={row?.discountToMerchant}
                                                                                readOnly
                                                                            />
                                                                            {errors?.discountToMerchant && (
                                                                                <div className="text-danger">
                                                                                    {errors?.discountToMerchant}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCssReadonly"
                                                                                name="discountToDaqi"
                                                                                value={row?.discountToDaqi}
                                                                                readOnly
                                                                            />
                                                                            {errors?.discountToDaqi && (
                                                                                <div className="text-danger">
                                                                                    {errors?.discountToDaqi}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control AddingRowCss"
                                                                                name="promotionalDiscount"
                                                                                value={row?.promotionalDiscount}
                                                                                onChange={(event) =>
                                                                                    handleUpdateRowChange(
                                                                                        event,
                                                                                        index,
                                                                                        "orderQuotation"
                                                                                    )
                                                                                }
                                                                            />
                                                                            {errors?.promotionalDiscount && (
                                                                                <div className="text-danger">
                                                                                    {errors?.promotionalDiscount}
                                                                                </div>
                                                                            )}
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        :
                                                        <tbody className="viewBody">
                                                            {viewOnlyData.map((data, index) => {
                                                                const rate = data?.rate;
                                                                const margin = data?.margin;
                                                                const formattedMargin = safeToFixed(margin);

                                                                return (
                                                                    <tr className="rowHeight">
                                                                        <td className="align-middle" style={{ textAlign: "center" }}>{index + 1}.</td>
                                                                        <td className={((merchantUserData?.statusName === "Pending" || merchantUserData?.statusName === "Quotation Pending" || merchantUserData?.statusName === "Merchant Approval Pending")) ? "align-middle" : "disabled align-middle"}>
                                                                            <div
                                                                                className="proPlusIconGreen"
                                                                                onClick={totalRoiAddRow}
                                                                            ><FaPlusCircle style={{ marginTop: '0px' }} />
                                                                            </div>
                                                                        </td>
                                                                        <td className={errors?.warehouseId ? "border-danger align-middle" : "quantitytd align-middle"}>
                                                                            <select
                                                                                className=""
                                                                                value={data.warehouseId || ""}
                                                                                name="warehouseId"
                                                                                disabled
                                                                                onChange={(event) => handleUpdateRowChange(event, index, "orderQuotation")}
                                                                                style={{width: '200px', border: '1px solid #f5f5f5', borderRadius: '4px', color: data.warehouseId ? "#000" : "#aaa"}}
                                                                            >
                                                                                <option value="" disabled style={{ color: "#aaa" }}>
                                                                                    Select Warehouse
                                                                                </option>
                                                                                {wareHouseData.map((warehouse) => (
                                                                                    <option key={warehouse.value} value={warehouse.value} style={{ color: "#000" }}>
                                                                                        {warehouse.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td className={errors?.brandId ? "border-danger align-middle" : "brandtd align-middle"}>
                                                                            <select
                                                                                className=""
                                                                                value={data.brandId || ""}
                                                                                name="brandId"
                                                                                disabled
                                                                                onChange={(event) => handleUpdateRowChange(event, index, "orderQuotation")}
                                                                                style={{ width: '200px', border: '1px solid #f5f5f5', borderRadius: '4px', color: data.brandId ? "#000" : "#aaa"}}
                                                                            >

                                                                                <option value="" disabled style={{ color: "#aaa" }}>
                                                                                    Select Brand
                                                                                </option>
                                                                                {brandData.map((brand) => (
                                                                                    <option key={brand.value} value={brand.value} style={{ color: "#000" }}>
                                                                                        {brand.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td className="typheadsec">
                                                                            <div className="visibleData">
                                                                                <div className="d-flex"><span className="prodcolor" title={data?.productName}>{data?.productName}</span></div>
                                                                                <div className="d-flex"><span className="cateColor">{data?.productCategory}</span> <span className="subcatcolor ms-auto">{data?.subCategory}</span></div>
                                                                                <div className="d-flex"><span className="brandcolor">{data?.brand}</span><span className="amtcolor ms-3">{data?.amountWithGST}</span><span className="distcolor ms-auto">{data?.name ? data?.name : data?.firstName + data?.lastName ? "" : "-"}</span></div>
                                                                                <div className="d-flex"><span className="" title={data?.size}>{data?.size}</span><span className="ms-auto" title={data?.color}>{data?.color}</span></div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="quantitytd align-middle">{data?.quantity}</td>
                                                                        <td className="align-middle">{data?.isGstApplicable == 1 ? "Yes" : "No"}</td>
                                                                        <td className="align-middle">{data?.listingPrice}</td>
                                                                        <td className="align-middle">{rate.toFixed(2)}</td>
                                                                        <td className="align-middle">{data?.amountWithGST}</td>
                                                                        <td className="align-middle">{data?.buyingTotalPrice ? data?.buyingTotalPrice : data?.totalBuyingAmount}</td>
                                                                        <td className="align-middle">{formattedMargin}</td>
                                                                        <td className="align-middle">{data?.discountToMerchant}</td>
                                                                        <td className="align-middle">{data?.discountToDaqi}</td>
                                                                        <td className="align-middle">{data?.promotionalDiscount}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    }
                                                    {(merchantUserData?.statusName === "Pending" || merchantUserData?.statusName === "Quotation Pending" || merchantUserData?.statusName === "Merchant Approval Pending") ?
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={5}></td>
                                                                <td className="text-center">{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(row?.quantity) || 0), 0)}</td>
                                                                <td></td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(safeToFixed(row?.listingPrice)) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(safeToFixed(row?.rate)) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(row?.totalAmount) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(safeToFixed(row?.totalBuyingAmount ? row?.totalBuyingAmount : row?.buyingTotalPrice)) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(safeToFixed(row?.margin)) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(row?.discountToMerchant) || 0), 0)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(row?.discountToDaqi) || 0), 0)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(row?.promotionalDiscount) || 0), 0)}</td>
                                                            </tr>
                                                        </tfoot>

                                                        :
                                                        <tfoot className="viewFooter">
                                                            <tr>
                                                                <td colSpan={5}></td>
                                                                <td className="text-center">{viewOnlyData.reduce((total, row) => total + (parseFloat(row?.quantity) || 0), 0)}</td>
                                                                <td></td>
                                                                <td>{viewOnlyData.reduce((total, row) => total + (parseFloat(safeToFixed(row?.listingPrice)) || 0), 0).toFixed(2)}</td>
                                                                <td>{viewOnlyData.reduce((total, row) => total + (parseFloat(safeToFixed(row?.rate)) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(row?.totalAmount) || 0), 0).toFixed(2)}</td>
                                                                <td>{formData?.orderQuotation.reduce((total, row) => total + (parseFloat(safeToFixed(row?.totalBuyingAmount ? row?.totalBuyingAmount : row?.buyingTotalPrice)) || 0), 0).toFixed(2)}</td>
                                                                <td>{viewOnlyData.reduce((total, row) => total + (parseFloat(safeToFixed(row?.margin)) || 0), 0).toFixed(2)}</td>
                                                                <td>{viewOnlyData.reduce((total, row) => total + (parseFloat(row?.discountToMerchant) || 0), 0)}</td>
                                                                <td>{viewOnlyData.reduce((total, row) => total + (parseFloat(row?.discountToDaqi) || 0), 0)}</td>
                                                                <td>{viewOnlyData.reduce((total, row) => total + (parseFloat(row?.promotionalDiscount) || 0), 0)}</td>
                                                            </tr>
                                                        </tfoot>
                                                    }
                                                </table>
                                            </div>

                                            <div>
                                                {isLoadingOn && <Loader />}
                                                {(merchantUserData?.statusName === "Pending" || merchantUserData?.statusName === "Quotation Pending" || merchantUserData?.statusName === "Merchant Approval Pending") && (
                                                    <div className="text-center mt-4">
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={handelUpdateSubmit}
                                                            disabled={isLoadingOn}
                                                        >Save</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* {showImage && ( */}
                        <div className={`right-image  ${showImage ? "slide-out" : "slide-in col-md-5"}`}>
                            <h5>Order Image</h5>
                            <div className="p-3 showWholeBorder">
                                <div className="row">
                                    {orderImageData &&
                                        orderImageData.map((item, index) => (
                                            <div key={index} className="col-md-12">
                                                <div className="mngimag">
                                                    <div className="imagemsngOrder">
                                                        <div className="viewoDoenload">
                                                            <button
                                                                className="btnviewImage"
                                                                onClick={() => viewImageOrder(item?.filePath)}
                                                            >
                                                                <FaEye />
                                                            </button>
                                                            <button className="btnviewImage">
                                                                <a href={item?.filePath} target="_blank">
                                                                    <FaDownload />
                                                                </a>
                                                            </button>
                                                        </div>
                                                        <img src={item?.filePath} alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        {/* )} */}


                    </div>

                    {/* -------------------- form row ---------------------- */}
                </div>
            </section>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className="modalCloseBtn" onClick={closeModal}>
                    <FaRegTimesCircle />
                </button>
                <div className="modalUserImage">
                    <img src={viewOrderImageModal} alt="modalImage" />
                </div>
            </Modal>

            {/* ------------- invoice View Modal ---------------------- */}
            <Modal
                isOpen={actionModalOpen}
                onRequestClose={closeModal}
                style={customStylesDistributor}
                contentLabel="Example Modal"
            >
                <button className="modalCloseBtn" onClick={closeModal}>
                    <FaRegTimesCircle />
                </button>
                {isLoadingOn ? <Loader /> : ""}
                <div className="dataImageViewAction">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mt-2">
                            <label htmlFor="invoiceFile">Select File</label>
                            <input
                                type="file"
                                // value={formDistributorData.invoiceFile}
                                onChange={handleChange}
                                accept="application/pdf"
                                name="invoiceFile"
                                className="form-control"
                                id="invoiceFile"
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="invoiceNumber">Invoice Number</label>
                            <input
                                type="text"
                                value={formDistributorData?.invoiceNumber}
                                onChange={handleChange}
                                name="invoiceNumber"
                                className="form-control"
                                id="invoiceNumber"
                                placeholder="Enter Number"
                            />
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="invoiceAmount">Invoice Amount</label>
                            <input
                                type="text"
                                value={formDistributorData?.invoiceAmount}
                                onChange={handleChange}
                                name="invoiceAmount"
                                className="form-control"
                                id="invoiceAmount"
                                placeholder="Enter Amount"
                            />
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="invoiceDate">Invoice Date</label>
                            <input
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                value={formDistributorData?.invoiceDate}
                                onChange={handleChange}
                                name="invoiceDate"
                                className="form-control"
                                id="invoiceDate"
                            />
                        </div>
                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>


            {/* ------------- agreement update Modal ---------------------- */}
            <Modal
                isOpen={agreementUpdateModal}
                onRequestClose={closeModal}
                style={customStylesDistributor}
                contentLabel="Example Modal"
            >
                <button className="modalCloseBtn" onClick={closeModal}>
                    <FaRegTimesCircle />
                </button>
                {isLoadingOn ? <Loader /> : ""}
                <div className="dataImageViewAction">
                    <form onSubmit={handleAgreementUpdateSubmit}>
                        <div className="form-group mt-2">
                            <label htmlFor="invoiceFile">Select File</label>
                            <input
                                type="file"
                                onChange={(event) => handleFileInputChange(event, 'file')}
                                accept="application/pdf"
                                name="invoiceFile"
                                className="form-control"
                                id="invoiceFile"
                            />
                        </div>

                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            {/* -------------------- Set Disbursement -------------------- */}
            <Modal
                isOpen={modalIsDisbursement}
                onRequestClose={closeModal}
                style={customStylesDisbursement}
                contentLabel="Example Modal"
            >
                <button className="modalCloseBtn" onClick={closeModal} style={closeButtonStyle}>
                    <FaRegTimesCircle />
                </button>
                {isLoadingOn ? <Loader /> : ""}
                <div className="dataImageViewAction">
                    <form onSubmit={handleUpdateDisbursedInvoice}>
                        <div className="form-group mt-2">
                            <label htmlFor="disbursementDistributor">Distributor Name</label>
                            <input
                                type="text"
                                disabled
                                value={formDisbursementData?.disbursementDistributor}
                                onChange={handleDisbursedChange}
                                name="disbursementDistributor"
                                className="form-control"
                                id="disbursementDistributor"
                                placeholder=""
                            />
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="disbursementInvoiceId">Invoice Number</label>
                            <input
                                type="number"
                                disabled
                                value={formDisbursementData?.disbursementInvoiceId}
                                onChange={handleDisbursedChange}
                                name="disbursementInvoiceId"
                                className="form-control"
                                id="disbursementInvoiceId"
                                placeholder=""
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="lenderName">Lender Name</label>
                            <input
                                type="text"
                                disabled
                                value={formDisbursementData?.lenderName}
                                onChange={handleDisbursedChange}
                                name="lenderName"
                                className="form-control"
                                id="lenderName"
                                placeholder=""
                            />
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="disbursementAmount">Distributor Disbursement Amount</label>
                            <input
                                type="number"
                                disabled
                                value={formDisbursementData?.disbursementAmount}
                                onChange={handleDisbursedChange}
                                name="disbursementAmount"
                                className="form-control"
                                id="disbursementAmount"
                                placeholder=""
                            />
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="totalDisbursementAmount">Total Disbursement Amount</label>
                            <input
                                disabled
                                type="text"
                                value={formDisbursementData?.totalDisbursementAmount}
                                onChange={handleDisbursedChange}
                                name="totalDisbursementAmount"
                                className="form-control"
                                id="totalDisbursementAmount"
                                placeholder=""
                            />
                        </div>

                        <div className="form-group mt-2">
                            {fileInputs.map((input, index) => (
                                <div className="row mb-0" key={input?.id}>
                                    <div className="col-md-11">
                                        <div className="mb-2">
                                            <label htmlFor={`checkDocument_${input?.id}`} className="form-label">
                                                {index === 0 ? 'Cheque (*)' : 'Cheque'}
                                            </label>
                                            <input
                                                type="file"
                                                accept=".pdf, .jpg, .jpeg, .png"
                                                onChange={(e) => handleFileChange(e, input?.id)}
                                                name={`checkDocument_${input?.id}`}
                                                className="form-control"
                                                id={`checkDocument_${input?.id}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        {index === 0 ? (
                                            <div className="proPlusIconGreen" onClick={handleAddFileInput}>
                                                <FaPlusCircle />
                                            </div>
                                        ) : (
                                            <div className="deletedIcon" onClick={() => handleRemoveFileInput(input?.id)}>
                                                <FaTrash />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="disbursedDate">Disbursed Date</label>
                            <input
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                value={formDisbursementData?.disbursedDate}
                                onChange={handleDisbursedChange}
                                name="disbursedDate"
                                className="form-control"
                                id="disbursedDate"
                            />
                        </div>

                        <div className="form-group mt-2">
                            <label htmlFor="utr">UTR Number</label>
                            <input
                                type="text"
                                value={formDisbursementData.utr}
                                onChange={handleDisbursedChange}
                                name="utr"
                                className="form-control"
                                id="utr"
                                placeholder="Enter Number"
                            />
                        </div>
                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsDisbursementApproval}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Disbursement Approval Modal"
            >
                <button className="modalCloseBtn" onClick={closeModal}>
                    <FaRegTimesCircle />
                </button>
                <h4 className="pleaseSelectStatus">Please Select Disbursement Approval</h4>
                <div className="modalUserStatus">
                    <div className="confirmationbtn mb-8">
                        <div className="wrapper_btnselt">

                            <button
                                onClick={() => setApprovalStatus("Pending")}
                                className={approvalStatus === "Pending" ? "selected option" : "option"}
                                style={{ backgroundColor: approvalStatus === "Pending" ? "#a2c11c" : "initial", color: "Black" }}
                            >
                                Pending
                            </button>

                            <button
                                onClick={() => setApprovalStatus("Yes")}
                                className={approvalStatus === "Yes" ? "selected option" : "option"}
                                style={{ backgroundColor: approvalStatus === "Yes" ? "#a2c11c" : "initial", color: "Black" }}
                            >
                                Yes
                            </button>

                            <button
                                onClick={() => setApprovalStatus("No")}
                                className={approvalStatus === "No" ? "selected option" : "option"}
                                style={{ backgroundColor: approvalStatus === "No" ? "#a2c11c" : "initial", color: "Black" }}
                            >
                                No
                            </button>
                        </div>
                    </div>

                    <div style={{ textAlign: "center", marginTop: 20 }}>
                        <button onClick={updateDisbursementApproval} className="btn btn-success">
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OrderDetails


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows?.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e?.target?.value);
                    onChange(e?.target?.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <div>
            <input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e?.target?.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row?.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e?.target?.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows?.length ? preFilteredRows[0]?.values[id] : 0;
        let max = preFilteredRows?.length ? preFilteredRows[0]?.values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row?.values[id], min);
            max = Math.max(row?.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e?.target?.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <input
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "70px",
                    marginRight: "0.5rem",
                }}
            />
            to
            <input
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "70px",
                    marginLeft: "0.5rem",
                }}
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy, //sort data
        usePagination //use pagination
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    //const firstPageRows = rows.slice(0, 10);

    return (
        <>
            <div>
                <table {...getTableProps()} className="dashboardTable">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="tableHeader"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="tableTd">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    {/* <tfoot>
                  {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()}>
                      {group.headers.map(column => (
                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                      ))}
                    </tr>
                  ))}
                </tfoot> */}
                </table>
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>{" "}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records <strong>{rows.length}</strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{" "}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(page);
                                    }}
                                    className="gotoPage"
                                />
                            </span>{" "}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[5, 10, 20, 30, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <br />
            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
